import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import KeyValueModel from 'models/KeyValueModel';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import SortingReportDetailSection from 'pod/receivedGoods/SortingReportDetailSection';
import InputNumber from 'components/InputNumber';
import { getTranslation, inputNumberStep } from 'util/helpers';
import { AdvisedGoodStatus } from 'util/enums';
import { I18N } from '../../../assets/i18n/i18n';
import { chunk } from 'lodash';
import * as classnames from 'classnames';
import ViewStore from 'stores/ViewStore';

type ColumnsType = 1 | 2 | 3 | 4 | 5 | 6;

interface IProcessingTimeProps {
  processingTimes: KeyValueModel[];
  advisedGood: AdvisedGoodsModel;
  isReadOnly: boolean;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  columns?: ColumnsType;
  title?: string;
  isValid?: boolean;
  decimalPlaces?: number;
  inputNumberStepValue?: string;
}

interface IDefaultProps {
  columns: ColumnsType;
  isValid: boolean;
}

class ProcessingTimeComponent extends React.Component<IProcessingTimeProps> {
  public static defaultProps: IDefaultProps = {
    columns: 1,
    isValid: true,
  };

  public render() {
    const {
      translateService: { t },
      columns,
      title,
      isValid,
      processingTimes,
    } = this.props;

    const sections = chunk(processingTimes, columns);

    const itemXS = columns > 0 && columns <= 12 ? Math.round(12 / columns) : 12;

    return (
      <SortingReportDetailSection
        title={!!title ? title : t.SORTINGREPORT_PROCESSING_TIME_TITLE}
        hasFieldWithError={!isValid}
      >
        <Col className="pt-2 pb-2">
          {sections.map((processingTimeItem: KeyValueModel[], sectionIndex: number) => {
            return (
              <FormGroup
                key={`ms-${sectionIndex}`}
                row
                className={classnames({ 'mb-0': sections.length === sectionIndex + 1 })}
              >
                {processingTimeItem.map((processingTime: KeyValueModel, index: number) => {
                  const key = `${processingTime.name}.${index}`;
                  return (
                    <Col className="pb-2 overflow-x-hidden" md={itemXS} xs={12} key={`mi-${sectionIndex}-${index}`}>
                      <Row>{this._renderProcessingTimeItem(processingTime, key)}</Row>
                    </Col>
                  );
                })}
              </FormGroup>
            );
          })}
        </Col>
      </SortingReportDetailSection>
    );
  }

  private _renderProcessingTimeItem(item: KeyValueModel, key: string) {
    return (
      <React.Fragment key={key}>
        {this._renderLabel(item, key)}
        {this._renderInput(item, key)}
      </React.Fragment>
    );
  }

  private _renderLabel(item: KeyValueModel, key: string) {
    return (
      <Col md={6} xs={8} className="align-self-center">
        <Label for={key}>{this._createLabel(item)}</Label>
      </Col>
    );
  }

  private _createLabel = (item: KeyValueModel) => {
    const {
      translateService: { t },
    } = this.props;
    return `${getTranslation(`SORTINGREPORT_PROCESSING_TIME_${item.name}` as keyof I18N, t)}${this._createUnitsLabel(item.units)}` ;
  };

  private _createUnitsLabel = (key: string) => {
    const {
      translateService: { t },
    } = this.props;
    const units = key ? getTranslation(`SORTINGREPORT_PROCESSING_TIME_UNIT_${key.replace(' ', '_').toUpperCase()}` as keyof I18N, t) : null;
    return units ? ` (${units})` : '';
  };

  private _renderInput(item: KeyValueModel, key: string): React.ReactNode | undefined {
    const {
      decimalPlaces,
      inputNumberStepValue,
    } = this.props;
    return (
      <Col md={3} xs={4}>
        <InputNumber
          key={`${key}.value`}
          data-test={`${key}.value`}
          disabled={!this._isProcessingTimeEnabled}
          className="width-100 form-control"
          id={key}
          value={item.value}
          decimalPlaces={decimalPlaces}
          step={inputNumberStepValue ?? inputNumberStep(decimalPlaces)}
          onChangeValue={(number: number) => item.changeValue(number)}
        />
      </Col>
    );
  }

  private get _isProcessingTimeEnabled() {
    const {
      isReadOnly,
      sessionStore: { isTier2, isTier3, isTier1, isAdmin },
      advisedGood: { status },
      viewStore: { isUK },
    } = this.props;
    if (isUK) {
      return (
        !isReadOnly &&
        (((isTier3 || isTier2 || isTier1) && [AdvisedGoodStatus.CLAIMED, AdvisedGoodStatus.IN_LAB].includes(status)) ||
          ((isTier2 || isTier1) && status === AdvisedGoodStatus.READY_FOR_TIER_2) ||
          (isTier1 && status === AdvisedGoodStatus.READY_FOR_SIGN_OFF) ||
          isAdmin)
      );
    }
    return (
      !isReadOnly &&
      ((isTier2 && [AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(status)) ||
        (isTier3 && status === AdvisedGoodStatus.UNLOADED))
    );
  }
}

export default inject('sessionStore', 'translateService', 'viewStore')(observer(ProcessingTimeComponent));
