import { action, computed, observable, observe, reaction, when, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';
import ValidateModel from 'models/ValidateModel';
import AdvisedGoodDeliveryModel from 'models/AdvisedGoodDeliveryModel';
import FileModel from 'models/FileModel';
import IdNameModel from 'models/IdNameModel';
import OperatorModel from 'models/OperatorModel';
import StockItemModel from 'models/StockItemModel';
import SubAdvisedGoodsModel, { ISubAdvisedGoodsModelConstructObj } from 'models/SubAdvisedGoodsModel';
import TransferModel from 'models/TransferModel';
import MaterialModel from 'models/MaterialModel';
import { AdvisedGoodStatus, AdvisedGoodWeighingTypes, CountryCode, DeductionsList, DepartmentCode } from 'util/enums';
import KeyValueModel, { IKeyValueModelConstructObject } from 'models/KeyValueModel';
import TenantModel from 'models/TenantModel';
import { cloneObj } from 'util/helpers';
import { HAS_CHANGED, MAX_RG_AMOUNT } from 'util/constants';
import { ISetHasChangedAndClearIsDirty } from 'util/objectUpdater';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';

export interface IAdvisedGoodsModelConstructPutAdvisedGood {
  balance: boolean;
  comment: string;
  files: Array<{ id: string }>;
  id: string;
  subAdvisedGoods: ISubAdvisedGoodsModelConstructObj[];
  yardLocationId: string;
  deduction: IKeyValueModelConstructObject;
  deliveryTypeNumber?: string;
  deliveryType?: string;
  packaging?: string;
  processingData?: IKeyValueModelConstructObject[];
}

export interface IWeightUpdateSocketResponse {
  advisedGoodId: string;
  weight: number;
}

export interface IEwcCodeUpdateSocketResponse {
  ewcCode: string;
}

export interface ICommentUpdateSocketResponse {
  comment: string;
}

export interface IProcessingTypesData {
  fieldName: string;
  units: string;
  id?: string;
}

interface IAdvisedGoodsModelValidationKeys {
  subAdvisedGoods: boolean;
  RGsBulkDensity: boolean;
  RGsFoundQuality: boolean;
  RGsMainType: boolean;
  RGsMaterialDescription: boolean;
  RGsWiDone: boolean;
  RGsContamination: boolean;
  RGsDescription: boolean;
  RGsGrossWeight: boolean;
  RGsProductForm: boolean;
  RGsProductQuality: boolean;
  processingData: boolean;
}

export type AdvisedGoodsValidatorKey = keyof Partial<IAdvisedGoodsModelValidationKeys>;

export interface IAdvisedGoodsParams {
  rgClassificationsSectionRequired?: boolean;
  rgContaminationsSectionRequired?: boolean;
  countryCode: CountryCode;
}

export default class AdvisedGoodsModel
  extends ValidateModel<IAdvisedGoodsModelValidationKeys, MaterialModel[], IAdvisedGoodsParams>
  implements ISetHasChangedAndClearIsDirty {
  constructor(public readonly advisedGoodsParams: IAdvisedGoodsParams) {
    super();
    makeObservable<AdvisedGoodsModel, '_isNeedSetGrossWeightOfFirstRGOfSAG'>(this, {
      advisedDescription: observable,
      advisedNetWeight: observable,
      advisedGrossWeight: observable,
      advisedTareWeight: observable,
      attachments: observable,
      balance: observable,
      claimedBy: observable,
      comment: observable,
      delivery: observable,
      isFlagged: observable,
      firstWeight: observable,
      secondWeight: observable,
      id: observable,
      ewcCode: observable,
      remarks: observable,
      status: observable,
      stockItem: observable,
      subAdvisedGoods: observable,
      supplierCode: observable,
      ticketNumber: observable,
      transfers: observable,
      vehicleRegistrationNumber: observable,
      deliveryTypeNumber: observable,
      deliveryType: observable,
      yardLocation: observable,
      classifiedBy: observable,
      completedBy: observable,
      flaggedBy: observable,
      unflaggedBy: observable,
      createdAt: observable,
      agTitle: observable,
      nonAdvisedGood: observable,
      signedOffBy: observable,
      updatedAt: observable,
      verifiedBy: observable,
      rolledbackBy: observable,
      deduction: observable,
      tenant: observable,
      departmentCode: observable,
      processingData: observable,
      packaging: observable,
      productForm: observable,
      productQuality: observable,
      weighingType: observable,
      valuableWeight: observable,
      totalCost: observable,
      createAllProcessingTypeItems: action,
      isUserChoseNoDeductionOption: computed,
      hasOnlyOneSubAdvisedGoodWithReceivedGoods: computed,
      hasOnlyOneDeliveryAGForUS: computed,
      isFirstRGOfFirstSubAGWeightExist: computed,
      totalAdvisedGoodGrossWeight: computed,
      totalAdvisedGoodNetWeight: computed,
      isDepartmentSTST: computed,
      isDepartmentHSS: computed,
      isDepartmentEUATUR: computed,
      isDepartmentEUASDSTI: computed,
      isDepartmentEUASDSNI: computed,
      totalAdvisedGoodWeightWithDeductions: computed,
      deliveryReceivedWeight: computed,
      firstSubAGReceivedGrossWeights: computed,
      shouldRecalculateFirstReceivedGoodWeight: computed,
      isFlaggedSomeReceivedGood: computed,
      isDirty: computed,
      amountOfFilledRG: computed,
      canNotCreateNewRG: computed,
      hasFirstSAGsReceivedGoods: computed,
      _isNeedSetGrossWeightOfFirstRGOfSAG: computed,
      advisedCalculatedNetWeight: computed,
      hasProductionFormAndQuality: computed,
      isRequiredGrossAndTareWeights: computed,
      isScaleWeights: computed,
      update: action,
      pushNewAttachment: action,
      pushTransfer: action,
      removeAttachment: action,
      setBalance: action,
      setComment: action,
      setFlagged: action,
      setStatus: action,
      setFirstWeight: action,
      setSecondWeight: action,
      setAdvisedNetWeight: action,
      setAdvisedGrossWeight: action,
      setAdvisedTareWeight: action,
      clearIsDirty: action,
      setEwcCode: action,
      setNameOfDeduction: action,
      setValueOfDeduction: action,
      setYardLocation: action,
      setRollbackBy: action,
      setClaimedBy: action,
      setFlaggedBy: action,
      setUnflaggedBy: action,
      setPackaging: action,
      changeGrossWeightOfFirstRGForSingleSAG: action,
      setGrossWeightOfFirstRGOfSAG: action,
      autoUpdateFirstReceivedGood: action,
      constructPutAdvisedGood: action,
      constructKeyValueModelArray: action,
      unflagAllReceivedGoods: action,
    });
    observe(this, this.onChange);
    when(
      () => this._isNeedSetGrossWeightOfFirstRGOfSAG,
      () => this.setGrossWeightOfFirstRGOfSAG(this.deliveryReceivedWeight)
    );
    reaction(
      () => this.shouldRecalculateFirstReceivedGoodWeight,
      () => this.changeGrossWeightOfFirstRGForSingleSAG()
    );
    reaction(
      () => this.firstSubAGReceivedGrossWeights,
      () => {
        if (
          this.isRequiredGrossAndTareWeights &&
          this.balance &&
          this.hasOnlyOneSubAdvisedGoodWithReceivedGoods &&
          this.deliveryReceivedWeight
        ) {
          this.changeGrossWeightOfFirstRGForSingleSAG();
        }
      }
    );
    reaction(
      () => this.isFlaggedSomeReceivedGood,
      (value) => this.setFlagged(value)
    );
    reaction(
      () => this.isFlagged,
      (value) => {
        if (!value) {
          this.unflagAllReceivedGoods();
        }
      }
    );
  }

  public createAllProcessingTypeItems = (types: IProcessingTypesData[]): void => {
    this.processingData = types.map((type: IProcessingTypesData) =>
      new KeyValueModel().update({ name: type.fieldName, units: type.units, id: type.id } as KeyValueModel)
    );
  };

  public advisedDescription?: string = '';
  public advisedNetWeight?: number = null;
  public advisedGrossWeight?: number = null;
  public advisedTareWeight?: number = null;
  public attachments?: FileModel[] = [];
  public balance?: boolean = false;
  public claimedBy?: OperatorModel = null;
  public comment?: string = null;
  public delivery?: AdvisedGoodDeliveryModel = null;
  public isFlagged?: boolean = false;
  public firstWeight?: number = null;
  public secondWeight?: number = null;
  public id?: string = null;
  public ewcCode?: string = '';
  public remarks?: string = '';
  public status?: AdvisedGoodStatus = null;
  public stockItem?: StockItemModel = null;
  public subAdvisedGoods?: SubAdvisedGoodsModel[] = [];
  public supplierCode?: string = '';
  public ticketNumber?: string = '';
  public transfers?: TransferModel[] = [];
  public vehicleRegistrationNumber?: string = '';
  public deliveryTypeNumber?: string = null;
  public deliveryType?: string = null;
  public yardLocation?: IdNameModel = null;
  public classifiedBy?: OperatorModel = null;
  public completedBy?: OperatorModel = null;
  public flaggedBy?: OperatorModel = null;
  public unflaggedBy?: OperatorModel = null;
  public createdAt?: string = '';
  public agTitle?: string = '';
  public nonAdvisedGood?: boolean = false;
  public signedOffBy?: OperatorModel = null;
  public updatedAt?: string = '';
  public verifiedBy?: OperatorModel = null;
  public rolledbackBy?: OperatorModel = null;
  public deduction?: KeyValueModel = new KeyValueModel().update({
    name: DeductionsList.NO_DEDUCTION,
  } as KeyValueModel);
  public tenant?: TenantModel = null;
  public departmentCode?: DepartmentCode = null;
  public processingData?: KeyValueModel[] = [];
  public packaging?: string = null;
  public productForm?: IdNameModel = null;
  public productQuality?: IdNameModel = null;
  public weighingType?: string = null;
  public valuableWeight?: number = null;
  public totalCost?: number = null;
  public costPerUnit?: number = null;

  public generalValidatorKeys: Array<keyof Partial<IAdvisedGoodsModelValidationKeys>> = [
    'subAdvisedGoods',
    'RGsDescription',
    'RGsGrossWeight',
  ];

  public validationKeysByCountryCode: Map<
    CountryCode,
    Array<keyof Partial<IAdvisedGoodsModelValidationKeys>>
  > = new Map<CountryCode, Array<keyof Partial<IAdvisedGoodsModelValidationKeys>>>([
    [CountryCode.US, this.generalValidatorKeys],
    [CountryCode.IT, this.generalValidatorKeys],
    [CountryCode.DE, this.generalValidatorKeys.concat([...this._getRGContaminationValidationFields(CountryCode.DE)])],
    [
      CountryCode.DE_D365,
      this.generalValidatorKeys.concat([
        ...this._getRGContaminationValidationFields(CountryCode.DE_D365),
        'RGsProductForm',
        'RGsProductQuality',
      ]),
    ],
    [CountryCode.UK, this.generalValidatorKeys.concat(['processingData'])],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  private _getRGContaminationValidationFields(
    countryCode: CountryCode
  ): Array<Partial<keyof IAdvisedGoodsModelValidationKeys>> {
    return this.advisedGoodsParams.countryCode === countryCode &&
      this.advisedGoodsParams.rgContaminationsSectionRequired
      ? ['RGsContamination']
      : [];
  }

  private _getRGClassificationValidationFields(
    countryCode: CountryCode
  ): Array<Partial<keyof IAdvisedGoodsModelValidationKeys>> {
    return this.advisedGoodsParams.countryCode === countryCode &&
      this.advisedGoodsParams.rgClassificationsSectionRequired
      ? ['RGsBulkDensity', 'RGsFoundQuality', 'RGsMainType', 'RGsMaterialDescription']
      : [];
  }

  private _getRGsWiDoneValidationFields(
    countryCode: CountryCode
  ): Array<Partial<keyof IAdvisedGoodsModelValidationKeys>> {
    return this.advisedGoodsParams.countryCode === countryCode ? ['RGsWiDone'] : [];
  }

  public get validators(): IAdvisedGoodsModelValidationKeys {
    return {
      subAdvisedGoods:
        this.subAdvisedGoods.length !== 0 && this.subAdvisedGoods.every((s) => s.validators.receivedGoods),
      RGsDescription: this.subAdvisedGoods.every((r) => r.validators.RGDescription),
      RGsGrossWeight: this.subAdvisedGoods.every((r) => r.validators.RGGrossWeight),
      RGsBulkDensity: this.subAdvisedGoods.every((r) => r.validators.RGBulkDensity),
      RGsFoundQuality: this.subAdvisedGoods.every((r) => r.validators.RGFoundQuality),
      RGsMainType: this.subAdvisedGoods.every((r) => r.validators.RGMainType),
      RGsMaterialDescription: this.subAdvisedGoods.every((r) => r.validators.RGMaterialDescription),
      RGsWiDone: this.subAdvisedGoods.every((r) => r.validators.RGWiDone),
      RGsContamination: this.subAdvisedGoods.every((r) => r.validators.RGContamination),
      RGsProductForm: this.subAdvisedGoods.every((r) => r.validators.RGProductForm),
      RGsProductQuality: this.subAdvisedGoods.every((r) => r.validators.RGProductQuality),
      processingData: this.processingData.some((p) => !!p.value),
    };
  }

  public get isUserChoseNoDeductionOption(): boolean {
    return this.deduction.name === DeductionsList.NO_DEDUCTION;
  }

  public get hasOnlyOneSubAdvisedGoodWithReceivedGoods(): boolean {
    return (
      ((this.subAdvisedGoods && this.subAdvisedGoods.length === 1 && !this._isUS) || this.hasOnlyOneDeliveryAGForUS) &&
      this.hasFirstSAGsReceivedGoods
    );
  }

  public get hasOnlyOneDeliveryAGForUS(): boolean {
    return this._isUS && this.delivery && this.delivery.advisedGoodsCount === 1;
  }

  public get isFirstRGOfFirstSubAGWeightExist(): boolean {
    return this.hasOnlyOneSubAdvisedGoodWithReceivedGoods && !!this.subAdvisedGoods[0].receivedGoods[0];
  }

  public get totalAdvisedGoodGrossWeight(): number {
    const sumReceivedGoodsGrossWeight = this.subAdvisedGoods.reduce((total, item) => {
      return item.receivedGoods.reduce((t, i) => {
        return i.grossWeight ? t + i.grossWeight : t;
      }, total);
    }, 0);

    return sumReceivedGoodsGrossWeight ? sumReceivedGoodsGrossWeight : 0;
  }

  public get totalAdvisedGoodNetWeight(): number {
    const sumReceivedGoodsNetWeight = this.subAdvisedGoods.reduce((total, item) => {
      return item.receivedGoods.reduce((t, i) => {
        return i.netWeight ? t + i.netWeight : t;
      }, total);
    }, 0);

    return sumReceivedGoodsNetWeight ? sumReceivedGoodsNetWeight : 0;
  }

  public get isDepartmentSTST() {
    return this.departmentCode === DepartmentCode.STST;
  }

  public get isDepartmentHSS() {
    return this.departmentCode === DepartmentCode.HSS;
  }

  public get isDepartmentEUATUR() {
    return this.departmentCode === DepartmentCode.EUATUR;
  }

  public get isDepartmentEUASDSTI() {
    return this.departmentCode === DepartmentCode.EUASDSTI;
  }

  public get isDepartmentEUASDSNI() {
    return this.departmentCode === DepartmentCode.EUASDSNI;
  }

  public get totalAdvisedGoodWeightWithDeductions() {
    return this._isDE && this.deduction
      ? this.totalAdvisedGoodGrossWeight + this.deduction.value
      : this.totalAdvisedGoodGrossWeight;
  }

  public get deliveryReceivedWeight(): number {
    if (this._isUS) {
      return this.delivery && this.delivery.deliveryGrossWeight && this.delivery.deliveryTareWeight
        ? this.delivery.deliveryGrossWeight - this.delivery.deliveryTareWeight
        : null;
    }

    if (this._isFR) {
      return this.firstWeight && typeof this.secondWeight === 'number' ? this.firstWeight - this.secondWeight : null;
    }

    if (this.nonAdvisedGood) {
      // IF ADVISED GOOD WAS CREATED BY TRANSFERING, RETURN GROSS WEIGHT
      return this.firstWeight;
    }

    return this.firstWeight && this.secondWeight ? this.firstWeight - this.secondWeight : null;
  }

  public get firstSubAGReceivedGrossWeights() {
    return (
      this.subAdvisedGoods &&
      this.subAdvisedGoods.length &&
      this.subAdvisedGoods[0] &&
      this.subAdvisedGoods[0].receivedGoods.map((rg) => rg.grossWeight)
    );
  }

  public get shouldRecalculateFirstReceivedGoodWeight() {
    return {
      balanceChange: this.isRequiredGrossAndTareWeights && this.balance,
      deductionChange:
        this.isRequiredGrossAndTareWeights &&
        this.balance &&
        this.deliveryReceivedWeight &&
        (this._isDE || this._isDE_D365) &&
        this.deduction &&
        this.deduction.value,
      grossAndTareWeightChange: this.firstWeight && this.secondWeight,
    };
  }

  public get isFlaggedSomeReceivedGood() {
    return (
      !!this.subAdvisedGoods &&
      this.subAdvisedGoods.some((sub: SubAdvisedGoodsModel) =>
        sub.receivedGoods.some((rg: ReceivedGoodsModel) => rg.flagged)
      )
    );
  }

  public get isDirty() {
    return (
      this.hasChanged ||
      this.subAdvisedGoods.some((x) => x.isDirty) ||
      this.deduction.hasChanged ||
      this.processingData.some((p) => p.hasChanged)
    );
  }

  public get amountOfFilledRG(): number {
    return this.subAdvisedGoods.reduce((res, sub) => res + sub.amountOfActiveRG, 0);
  }

  public get canNotCreateNewRG() {
    return this._isIT && this.amountOfFilledRG >= MAX_RG_AMOUNT;
  }

  public get hasFirstSAGsReceivedGoods() {
    return this.subAdvisedGoods && this.subAdvisedGoods[0] && !!this.subAdvisedGoods[0].receivedGoods.length;
  }

  private get _isNeedSetGrossWeightOfFirstRGOfSAG() {
    return (
      this.isRequiredGrossAndTareWeights &&
      this.balance &&
      this.hasOnlyOneDeliveryAGForUS &&
      !!this.deliveryReceivedWeight &&
      this.hasFirstSAGsReceivedGoods &&
      this.subAdvisedGoods[0].receivedGoods[0].grossWeight === null
    );
  }

  public get advisedCalculatedNetWeight() {
    return (
      (!!this.advisedGrossWeight ? this.advisedGrossWeight : 0) -
      (!!this.advisedTareWeight ? this.advisedTareWeight : 0)
    );
  }

  public get hasProductionFormAndQuality(): boolean {
    return Boolean(this.productForm && this.productQuality);
  }

  public get isRequiredGrossAndTareWeights(): boolean {
    return !!this.weighingType && this.weighingType !== AdvisedGoodWeighingTypes.SCALE;
  }

  public get isScaleWeights(): boolean {
    return this.weighingType === AdvisedGoodWeighingTypes.SCALE;
  }

  private get _isUS() {
    return this.advisedGoodsParams.countryCode === CountryCode.US;
  }

  private get _isIT() {
    return this.advisedGoodsParams.countryCode === CountryCode.IT;
  }

  private get _isDE() {
    return this.advisedGoodsParams.countryCode === CountryCode.DE;
  }

  private get _isDE_D365() {
    return this.advisedGoodsParams.countryCode === CountryCode.DE_D365;
  }

  private get _isFR() {
    return this.advisedGoodsParams.countryCode === CountryCode.FR;
  }

  public onChange = (change: IObjectDidChange) => {
    const isHasChanged = change.name === HAS_CHANGED;
    const isFlagged = change.name === 'flagged';
    const isStatus = change.name === 'status';
    const isAttachments = change.name === 'attachments';
    if (!isHasChanged && !isFlagged && !isStatus && !isAttachments) {
      this.setHasChanged(true);
    }
  };

  private _mergeCurrentProcessingWithNew(data: KeyValueModel[]): KeyValueModel[] {
    data.forEach((keyValueModelWithNewValue: KeyValueModel) => {
      const item = this.processingData.find((keyValueModel) => {
        return keyValueModel.name === keyValueModelWithNewValue.name;
      });

      if (item) {
        const newKeyValue = keyValueModelWithNewValue.value === null ? null : +keyValueModelWithNewValue.value;
        item.id = keyValueModelWithNewValue.id;
        item.changeValue(newKeyValue);
      }
    });

    return this.processingData;
  }

  public update = (obj: AdvisedGoodsModel, defaultMaterials: MaterialModel[], nonDefaultMaterial: MaterialModel[]) => {
    // NOSONAR
    const newAdvisedGoods: AdvisedGoodsModel = cloneObj(obj);

    if (newAdvisedGoods) {
      if (newAdvisedGoods.subAdvisedGoods) {
        newAdvisedGoods.subAdvisedGoods = newAdvisedGoods.subAdvisedGoods.map((sag: SubAdvisedGoodsModel) =>
          new SubAdvisedGoodsModel({ defaultMaterials, receivedGoodsParams: { ...this.advisedGoodsParams } }).update(
            sag,
            nonDefaultMaterial
          )
        );
      }
      if (newAdvisedGoods.transfers) {
        newAdvisedGoods.transfers = newAdvisedGoods.transfers.map((r: TransferModel) => new TransferModel().update(r));
      }
      if (newAdvisedGoods.delivery) {
        newAdvisedGoods.delivery = new AdvisedGoodDeliveryModel().update(newAdvisedGoods.delivery);
      }
      if (newAdvisedGoods.attachments && newAdvisedGoods.attachments.length !== 0) {
        newAdvisedGoods.attachments = newAdvisedGoods.attachments.map((r: FileModel) => new FileModel().update(r));
      }
      if (newAdvisedGoods.claimedBy) {
        newAdvisedGoods.claimedBy = new OperatorModel().update(newAdvisedGoods.claimedBy);
      }
      if (newAdvisedGoods.flaggedBy) {
        newAdvisedGoods.flaggedBy = new OperatorModel().update(newAdvisedGoods.flaggedBy);
      }
      if (newAdvisedGoods.unflaggedBy) {
        newAdvisedGoods.unflaggedBy = new OperatorModel().update(newAdvisedGoods.unflaggedBy);
      }
      if (newAdvisedGoods.signedOffBy) {
        newAdvisedGoods.signedOffBy = new OperatorModel().update(newAdvisedGoods.signedOffBy);
      }
      if (newAdvisedGoods.classifiedBy) {
        newAdvisedGoods.classifiedBy = new OperatorModel().update(newAdvisedGoods.classifiedBy);
      }
      if (newAdvisedGoods.completedBy) {
        newAdvisedGoods.completedBy = new OperatorModel().update(newAdvisedGoods.completedBy);
      }
      if (newAdvisedGoods.verifiedBy) {
        newAdvisedGoods.verifiedBy = new OperatorModel().update(newAdvisedGoods.verifiedBy);
      }
      if (newAdvisedGoods.rolledbackBy) {
        newAdvisedGoods.rolledbackBy = new OperatorModel().update(newAdvisedGoods.rolledbackBy);
      }
      if (newAdvisedGoods.deduction) {
        newAdvisedGoods.deduction = new KeyValueModel().update(newAdvisedGoods.deduction);
      } else {
        delete newAdvisedGoods.deduction;
      }

      if (newAdvisedGoods.processingData) {
        newAdvisedGoods.processingData = this._mergeCurrentProcessingWithNew(newAdvisedGoods.processingData);
      }

      if (newAdvisedGoods.tenant) {
        newAdvisedGoods.tenant = new TenantModel().update(newAdvisedGoods.tenant);
      }

      if (newAdvisedGoods.productForm) {
        newAdvisedGoods.productForm = new IdNameModel().update(newAdvisedGoods.productForm);
      }

      if (newAdvisedGoods.productQuality) {
        newAdvisedGoods.productQuality = new IdNameModel().update(newAdvisedGoods.productQuality);
      }

      newAdvisedGoods.weighingType = newAdvisedGoods.weighingType || AdvisedGoodWeighingTypes.WEIGHBRIDGE;
    }

    this.updater.update(this, newAdvisedGoods, AdvisedGoodsModel, this.advisedGoodsParams);
    this._setNewValidationFields(newAdvisedGoods);
    return this;
  };

  public pushNewAttachment(obj: FileModel) {
    this.attachments.push(new FileModel().update(obj));
  }

  public pushTransfer(obj: TransferModel) {
    this.transfers.push(new TransferModel().update(obj));
  }

  public removeAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((att) => att.id !== attachmentId);
  }

  public setBalance(val: boolean) {
    this.balance = val;
  }

  public setComment(val: string) {
    this.comment = val;
  }

  public setFlagged(val: boolean) {
    this.isFlagged = val;
  }

  public setStatus(val: AdvisedGoodStatus) {
    this.status = val;
  }

  public setFirstWeight(val: number) {
    this.firstWeight = val;
  }

  public setSecondWeight(val: number) {
    this.secondWeight = val;
  }

  public setAdvisedNetWeight(val: number) {
    this.advisedNetWeight = val;
  }

  public setAdvisedGrossWeight(val: number) {
    this.advisedGrossWeight = val;
  }

  public setAdvisedTareWeight(val: number) {
    this.advisedTareWeight = val;
  }

  public clearIsDirty = () => {
    this.setHasChanged(false);
    this.deduction.setHasChanged(false);

    if (this.subAdvisedGoods && this.subAdvisedGoods.some((sb) => sb.isDirty)) {
      this.subAdvisedGoods.forEach((subAG) => subAG.clearIsDirty());
    }

    if (this.processingData && this.processingData.some((pd) => pd.hasChanged)) {
      this.processingData.forEach((pd) => pd.setHasChanged(false));
    }
  };

  public setEwcCode(newEwcCode: string) {
    this.ewcCode = newEwcCode;
  }

  public setNameOfDeduction = (item: IdNameModel) => {
    this.deduction.changeName(item.id);
    if (this.isUserChoseNoDeductionOption) {
      this.setValueOfDeduction(null);
    }
  };

  public setValueOfDeduction = (newValue: number) => {
    this.deduction.changeValue(newValue);
  };

  public setYardLocation = (newLocation: IdNameModel) => {
    this.yardLocation = newLocation;
  };

  public setRollbackBy = (rollbackPerson: OperatorModel) => {
    this.rolledbackBy = new OperatorModel().update(rollbackPerson);
  };

  public setClaimedBy = (claimedBy: OperatorModel) => {
    this.claimedBy = new OperatorModel().update(claimedBy);
  };

  public setFlaggedBy = (flaggedBy: OperatorModel) => {
    this.flaggedBy = new OperatorModel().update(flaggedBy);
  };

  public setUnflaggedBy = (unflaggedBy: OperatorModel) => {
    this.unflaggedBy = new OperatorModel().update(unflaggedBy);
  };

  public setPackaging = (val: string) => {
    this.packaging = val;
  };

  public changeGrossWeightOfFirstRGForSingleSAG() {
    // APPLY BALANCE, ONLY IF ONE SUB-ADVISED-GOOD EXISTS AND THIS SUB-ADVISED-GOOD HAS RECEIVED-GOOD
    if (!this.isFirstRGOfFirstSubAGWeightExist) {
      return;
    }

    const deductionValue = this.deduction ? this.deduction.value : 0;
    const grossWeight =
      this._isDE || this._isDE_D365 ? this.deliveryReceivedWeight - deductionValue : this.deliveryReceivedWeight;
    const roundedGrossWeight = parseFloat(grossWeight.toFixed(4));

    if (this.balance && this.deliveryReceivedWeight) {
      this.setGrossWeightOfFirstRGOfSAG(this.autoUpdateFirstReceivedGood(roundedGrossWeight));
    } else if (this.isDirty) {
      this.setGrossWeightOfFirstRGOfSAG(roundedGrossWeight);
    }
  }

  public setGrossWeightOfFirstRGOfSAG(grossWeight: number) {
    this.subAdvisedGoods[0].receivedGoods[0].setGrossWeight(grossWeight);
  }

  // BALANCE: CALCULATE GROSSWEIGHT OF FIRST RECIEVED GOOD
  public autoUpdateFirstReceivedGood = (receivedWeight: number) => {
    const value = this.firstSubAGReceivedGrossWeights.reduce((res, grossWeight, index) => {
      if (index !== 0) {
        res -= grossWeight ? grossWeight : 0;
      }
      return res;
    }, receivedWeight);

    return this.subAdvisedGoods[0].receivedGoods && this.subAdvisedGoods[0].receivedGoods.length !== 1
      ? value
      : receivedWeight;
  };

  // CONSTRUCT OBJ FOR PUT ADVISED GOOD
  public constructPutAdvisedGood(): IAdvisedGoodsModelConstructPutAdvisedGood {
    const res: IAdvisedGoodsModelConstructPutAdvisedGood = {
      balance: this.balance,
      comment: this.comment,
      files: this.attachments.map((file: FileModel) => ({ id: file.id })),
      id: this.id,
      subAdvisedGoods: this.subAdvisedGoods.map((subAdvisedGood) => subAdvisedGood.constructSaveObj()),
      deliveryTypeNumber: this.deliveryTypeNumber,
      deliveryType: this.deliveryType,
      yardLocationId: this.yardLocation.id,
      deduction: this.isUserChoseNoDeductionOption ? null : this.deduction.constructObject,
      packaging: this.packaging,
    };
    if (this.processingData) {
      res.processingData = this.constructKeyValueModelArray(this.processingData);
    }
    return res;
  }
  public constructKeyValueModelArray = (keyValueModelArray: KeyValueModel[]): IKeyValueModelConstructObject[] => {
    return keyValueModelArray
      .filter((keyValueModel) => keyValueModel.value !== null)
      .map((keyValueModel) => keyValueModel.constructObject);
  };

  public unflagAllReceivedGoods() {
    return this.subAdvisedGoods.forEach((sub: SubAdvisedGoodsModel) =>
      sub.receivedGoods.forEach((rg: ReceivedGoodsModel) => {
        if (rg.flagged) {
          rg.setFlagged(false);
        }
      })
    );
  }

  private _setNewValidationFields(newAdvisedGoods: AdvisedGoodsModel) {
    if (newAdvisedGoods?.departmentCode === DepartmentCode.STST) {
      if (this.advisedGoodsParams.countryCode === CountryCode.DE) {
        this.validationKeysByCountryCode.set(CountryCode.DE, [
          ...this.validationKeysByCountryCode.get(CountryCode.DE),
          ...this._getRGsWiDoneValidationFields(CountryCode.DE),
          ...this._getRGClassificationValidationFields(CountryCode.DE),
        ]);
      }
      if (this.advisedGoodsParams.countryCode === CountryCode.DE_D365) {
        this.validationKeysByCountryCode.set(CountryCode.DE_D365, [
          ...this.validationKeysByCountryCode.get(CountryCode.DE_D365),
          ...this._getRGClassificationValidationFields(CountryCode.DE_D365),
        ]);
      }
    }
  }
}
