import RootStore from 'stores/RootStore';
import RootService from 'services/RootService';
import { action, observable, makeObservable } from 'mobx';
import AdvisedGoodsModel, {
  IAdvisedGoodsModelConstructPutAdvisedGood,
  IProcessingTypesData,
} from 'models/AdvisedGoodsModel';
import UploadAndDownloadAttachmentsAbstractStore from 'stores/UploadAndDownloadAttachmentsAbstractStore';
import { AdvisedGoodStatus, DeliveryStatus } from 'util/enums';
import KeyValueModel from 'models/KeyValueModel';
import AllWeightsModel from 'models/AllWeightsModel';
import OperatorModel from 'models/OperatorModel';
import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import { READY_FOR_SIGN_OFF_VALUE } from 'util/constants';

export interface IUnloadedResponse {
  status: AdvisedGoodStatus;
  deduction: KeyValueModel;
}

export interface IFlaggedResponse {
  isFlagged: boolean;
  flaggedBy: OperatorModel;
  unflaggedBy: OperatorModel;
}

export interface ISignOffAgResponse {
  deliveryStatus: DeliveryStatus;
}

export default class AdvisedGoodsStore extends UploadAndDownloadAttachmentsAbstractStore {
  public constructor(protected readonly rootService: RootService, private readonly rootStore: RootStore) {
    super(rootService);

    makeObservable<AdvisedGoodsStore, '_setProcessingTypes'>(this, {
      content: observable,
      allWeights: observable,
      processingTypes: observable,
      setContent: action,
      setAllWeights: action,
      _setProcessingTypes: action,
    });
  }

  public urlSection: string = 'advised-goods';

  public content: AdvisedGoodsModel = null;
  public allWeights: AllWeightsModel = null;
  public processingTypes: IProcessingTypesData[] = [];

  public getAdvisedGoodsById = async (advisedGoodsId: string): Promise<AdvisedGoodsModel> => {
    const advisedGood = await this._getAdvisedGoodByIdRequest(advisedGoodsId).then((res) =>
      this.getUpdatedAGModelInstanceWithProcessing(res)
    );
    this.rootStore.viewStore.setTenant(advisedGood.tenant);
    return advisedGood;
  };

  public putAdvisedGoodsById = (advisedGood: IAdvisedGoodsModelConstructPutAdvisedGood) => {
    return this.rootService.ajaxService.put(`advised-goods/${advisedGood.id}`, advisedGood);
  };

  public postStatusRollback = (advisedGoodId: string) => {
    return this.rootService.ajaxService.post(`advised-goods/${advisedGoodId}/rollback`);
  };

  public postFlagAdvisedGood = (advisedGoodId: string, isFlagged: boolean): Promise<IFlaggedResponse> => {
    return this.rootService.ajaxService.post(`advised-goods/${advisedGoodId}/flag`, { isFlagged });
  };

  public postTier2Verify = (advisedGoodId: string) => {
    return this.rootService.ajaxService.post(`advised-goods/${advisedGoodId}/verify`);
  };

  public postTier3Complete = (advisedGoodId: string) => {
    return this.rootService.ajaxService.post(`advised-goods/${advisedGoodId}/complete`);
  };

  public deleteAG = (advisedGoodId: string) => {
    return this.rootService.ajaxService.delete(`advised-goods/${advisedGoodId}`);
  };

  public postAdvisedGoodUnload = (advisedGood: AdvisedGoodsModel): Promise<IUnloadedResponse> => {
    return this.rootService.ajaxService.post(
      `advised-goods/${advisedGood.id}/unload`,
      advisedGood.isUserChoseNoDeductionOption ? null : advisedGood.deduction.constructObject
    );
  };

  public postClaimAdvisedGood = (advisedGoodId: string, yardLocationId?: string) => {
    return this.rootService.ajaxService.post(
      `advised-goods/${advisedGoodId}/claim`,
      yardLocationId && { yardLocationId }
    );
  };

  public postUnclaimAdvisedGood = (advisedGoodId: string) => {
    return this.rootService.ajaxService.post(`advised-goods/${advisedGoodId}/unclaim`);
  };

  public getAllWeightsByAGId = (advisedGoodId: string) => {
    return this.rootService.ajaxService.get(`advised-goods/${advisedGoodId}/all-weights`);
  };

  public getCommonData = async () => {
    const result = await this.rootService.ajaxService.get('common/advised-good');
    if (!!result.processingTypes) {
      this._setProcessingTypes(result.processingTypes);
    }
    return result;
  };

  public getUpdatedAGModelInstanceWithProcessing = (newAG: AdvisedGoodsModel): AdvisedGoodsModel => {
    const { defaultMaterials, nonDefaultMaterial } = this.rootStore.commonStore;
    const { countryCode, rgClassificationsSectionRequired, rgContaminationsSectionRequired } = this.rootStore.viewStore;
    const instance = new AdvisedGoodsModel({
      countryCode,
      rgClassificationsSectionRequired,
      rgContaminationsSectionRequired,
    });
    if (this.processingTypes.length) {
      instance.createAllProcessingTypeItems(this.processingTypes);
    }
    instance.update(newAG, defaultMaterials, nonDefaultMaterial);
    instance.clearIsDirty();

    return instance;
  };

  public postSignOffAdvisedGood = (advisedGoodId: string): Promise<ISignOffAgResponse> => {
    return this._postSignOffAdvisedGoodRequest(advisedGoodId);
  };

  public hasAgSignOff = (ag: DeliveryListAdvisedGoodModel | AdvisedGoodsModel): boolean => {
    const {
      viewStore: { hasAgSignOff },
      sessionStore: { isTier1 },
    } = this.rootStore;

    return hasAgSignOff && isTier1 && ag.status === READY_FOR_SIGN_OFF_VALUE;
  };

  public setContent = (newContent: AdvisedGoodsModel) => {
    this.content = this.getUpdatedAGModelInstanceWithProcessing(newContent);
  };

  public setAllWeights = (newAllWeights: AllWeightsModel) => {
    const {
      commonStore: { defaultMaterials, nonDefaultMaterial },
      viewStore: { countryCode, rgClassificationsSectionRequired, rgContaminationsSectionRequired },
    } = this.rootStore;
    let newContent: AdvisedGoodsModel = null;
    if (newAllWeights && newAllWeights.advisedGoods) {
      const filteredAG = newAllWeights.advisedGoods.filter((ag) => this.content && ag.id !== this.content.id);
      newContent = newAllWeights.advisedGoods.find((ag) => ag.id === this.content.id);
      if (newContent) {
        if (newContent.firstWeight) {
          this.content.setFirstWeight(newContent.firstWeight);
        }
        if (newContent.secondWeight) {
          this.content.setSecondWeight(newContent.secondWeight);
        }
        if (newContent.advisedNetWeight) {
          this.content.setAdvisedNetWeight(newContent.advisedNetWeight);
        }
        if (newContent.advisedTareWeight) {
          this.content.setAdvisedTareWeight(newContent.advisedTareWeight);
        }
      }

      newAllWeights.advisedGoods = filteredAG;
    }

    this.allWeights = new AllWeightsModel({
      countryCode,
      rgClassificationsSectionRequired,
      rgContaminationsSectionRequired,
    }).update(newAllWeights, defaultMaterials, nonDefaultMaterial);

    if (newContent) {
      this.allWeights.advisedGoods.push(this.content);
    }
  };

  private _setProcessingTypes = (types: IProcessingTypesData[]) => {
    this.processingTypes = types;
  };

  private _getAdvisedGoodByIdRequest = async (advisedGoodsId: string) => {
    return this.rootService.ajaxService.get(`advised-goods/${advisedGoodsId}`);
  };

  private _postSignOffAdvisedGoodRequest = async (advisedGoodId: string): Promise<ISignOffAgResponse> => {
    return this.rootService.ajaxService.post(`advised-goods/${advisedGoodId}/signoff`);
  };
}
