import * as React from 'react';
import TenantModel, { ITenantModelValidationKeys } from 'models/TenantModel';
import { TenantSectionModel } from 'models/TenantSectionModel';
import TranslateService from 'services/TranslateService';
import GeneralSection from 'pod/superAdmin/tenants/GeneralSection';
import ConfigurationSection from 'pod/superAdmin/tenants/ConfigurationSection';
import IntegrationSection from 'pod/superAdmin/tenants/IntegrationSection';
import RequiredOptionsSection from 'pod/superAdmin/tenants/RequiredOptionsSection';
import AdminSection from 'pod/superAdmin/tenants/AdminSection';
import { CountryCode } from 'util/enums';
import CostRatesSection from './CostRatesSection';

export const createSections = (tenant: TenantModel, countryCode: CountryCode, translateService: TranslateService): TenantSectionModel[] => {
  const { t } = translateService;
  const generalSection = new TenantSectionModel({
    name: t.SUPERADMIN_TENANTSECTIONS_GENERAL_SECTION,
    component: () => <GeneralSection tenant={tenant} />,
    index: 0,
    active: true,
    validationRules: [
      ...tenant.generalValidatorKeys,
      ...(countryCode !== CountryCode.DE_D365 && ['currentSequenceValue']),
      ...(countryCode === CountryCode.IT && ['currentLabSequenceValue']),
      ...(countryCode === CountryCode.DE_D365 && ['site'])] as Array<keyof Partial<ITenantModelValidationKeys>>,
  });

  const configurationSection = new TenantSectionModel({
    name: t.SUPERADMIN_TENANTSECTIONS_CONFIGURATION_SECTION,
    component: () => <ConfigurationSection tenant={tenant} />,
    index: 1,
    active: false,
    validationRules: []
  });

  const requiredOptionsSection = new TenantSectionModel({
    name: t.SUPERADMIN_TENANTSECTIONS_REQUIRED_OPTIONS_SECTION,
    component: () => <RequiredOptionsSection tenant={tenant} />,
    index: 2,
    active: false,
    validationRules: []
  });

  const adminSection = new TenantSectionModel({
    name: t.SUPERADMIN_TENANTADMIN_ADMINS,
    component: () => <AdminSection tenant={tenant} />,
    index: 3,
    active: false,
    validationRules: []
  });

  const integrationSection = new TenantSectionModel({
    name: t.SUPERADMIN_TENANTSECTIONS_INTEGRATION_SECTION,
    component: () => <IntegrationSection tenant={tenant} />,
    index: tenant.id ? 4 : 3,
    active: false,
    validationRules: ['sharepointUploadFolderId']
  });

  const costPerSection = new TenantSectionModel({
    name: t.SUPERADMIN_TENANTSECTIONS_COST_TRACKING_SECTION,
    component: () => <CostRatesSection tenant={tenant} />,
    index: tenant.id ? 5 : 4,
    active: false,
    validationRules: ['currency', 'costPerUnit', 'amountDecimalPlaces', 'priceDecimalPlaces'],
  });

  return [
    generalSection,
    configurationSection,
    requiredOptionsSection,
    ...(tenant.id ? [ adminSection ] : []),
    ...(countryCode === CountryCode.DE_D365 || countryCode === CountryCode.DE ? [integrationSection] : []),
    ...(tenant.costTrackingEnabled && countryCode === CountryCode.DE_D365 ? [costPerSection] : [])
  ];
}
