import * as React from 'react';
import { Button, Col, Container, FormGroup, Label, Modal, ModalHeader, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { getTranslation, inputNumberStep } from 'util/helpers';
import * as classnames from 'classnames';
import env from 'env';

import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import LabAnalysisModel from 'models/LabAnalysisModel';
import AdvisedGoodLabAnalysisComponent from 'pod/receivedGoods/AdvisedGoodLabAnalysisComponent';
import MaterialsComponent from 'components/MaterialsComponent';
import PrintComponent from 'components/PrintComponent';
import ReceivedGoodsModel, { ReceivedGoodsValidatorKey } from 'models/ReceivedGoodsModel';
import IdNameModel from 'models/IdNameModel';
import SessionStore from 'stores/SessionStore';
import CommonStore from 'stores/CommonStore';
import TranslateService from 'services/TranslateService';
import { I18N } from '../../../assets/i18n/i18n';
import InputNumber from 'components/InputNumber';
import Contamination from 'pod/receivedGoods/Contamination';
import PicturesComponent from 'components/PicturesComponent';
import ReceivedGoodsStore, { IReceivedGoodsCommonData } from 'pod/receivedGoods/ReceivedGoodsStore';
import Autocomplete from 'domain/Autocomplete';
import {
  AdvisedGoodStatus,
  DepartmentCode,
  LasernetDocumentTypes,
  Position,
  SortingReportRequiredFields,
} from 'util/enums';
import { BORDER_DANGER, NUMBER_OF_DECIMALS_PROCESSING_TIME, TEXT_DANGER } from 'util/constants';
import StockItemModel from 'models/StockItemModel';
import IdNameActiveModel from 'models/IdNameActiveModel';
import ViewStore from 'stores/ViewStore';
import { ITransferData } from 'models/TransferModel';
import TransferComponent from 'pod/receivedGoods/TransferComponent';
import InputWithNullCheck from 'components/InputWithNullCheck';
import SortingReportDetailSection from 'pod/receivedGoods/SortingReportDetailSection';
import ProcessingTimeComponent from 'pod/receivedGoods/ProcessingTimeComponent';
import MeasurementComponent from 'pod/receivedGoods/MeasurementComponent';
import ModalConfirmation from 'domain/ModalConfirmation';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import ProductFormAndQuality from 'components/ProductFormAndQuality';
import StockItem from 'components/StockItemComponent';
import LasernetPrintComponent from 'components/LasernetPrintComponent';
import CostDetailsComponent from './CostDetailsComponent';

interface IProps {
  advisedGood: AdvisedGoodsModel;
  item: ReceivedGoodsModel;
  isReadOnly: boolean;
  isStockCodeReadOnly: boolean;
  isStockCodeInvalid: boolean;
  isRGWeightReadOnly: boolean;
  isRemarksReadOnly: boolean;
  labAnalysisCreate: (receivedGood: ReceivedGoodsModel, labStatusSelected: string) => void;
  transferDeliveredGood: (deliveredGood: ReceivedGoodsModel, transfer: ITransferData) => Promise<void>;
  descriptionMaxLength: number;
  sessionStore?: SessionStore;
  commonStore?: CommonStore;
  receivedGoodsStore?: ReceivedGoodsStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  isNeedToValidateRGs?: boolean;
  allowNegativeGrossWeight?: boolean;
  onBlurGrossWeightHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IState {
  labStatusSelected: string;
  departmentCode: DepartmentCode;
  isPrintYardModalOpen: boolean;
  labAnalysisCreateConfirmationModal: boolean;
}

class SortingReportDetailComponent extends React.Component<IProps, IState> {
  public readonly state: IState = {
    labStatusSelected: '',
    departmentCode: null,
    isPrintYardModalOpen: false,
    labAnalysisCreateConfirmationModal: false,
  };

  public componentDidMount(): void {
    const {
      receivedGoodsStore: { getCommonDataForSortingReport, processingTypes },
      item,
      viewStore: { isDE },
      advisedGood: { isDepartmentSTST },
      commonStore: { getCommonLab },
    } = this.props;

    getCommonLab();

    getCommonDataForSortingReport().then((commonData: IReceivedGoodsCommonData) => {
      if (isDE && isDepartmentSTST && !item.bulkDensity) {
        const wasDirty = item.isDirty;
        item.setBulkDensity(this.props.receivedGoodsStore.defaultBulkDensity);
        if (!wasDirty) {
          item.clearIsDirty();
        }
      }

      item.mergeCommonProcessData(commonData.processingTypes);
    });
  }

  public render() {
    const { isPrintYardModalOpen } = this.state;
    const {
      item,
      isReadOnly,
      isStockCodeReadOnly,
      isStockCodeInvalid,
      isRGWeightReadOnly,
      isRemarksReadOnly,
      translateService: { t },
      commonStore: { printers, nonDefaultMaterial, labStatuses },
      receivedGoodsStore,
      sessionStore: { isTier1 },
      viewStore: { numberOfDecimals, isDE_D365, isDE, hasOverwriteRgDescriptionDialogEnabled },
      advisedGood,
      transferDeliveredGood,
      allowNegativeGrossWeight,
      onBlurGrossWeightHandler,
    } = this.props;

    return (
      <Container>
        <Row className="bg-white border-bottom padding-top-10">
          <Col className="pt-2 pb-2">
            {this._hasStockCode && (
              <FormGroup data-test="stock-code" row>
                <Label xs={3}>{t.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE}</Label>
                <Col xs={9}>
                  <StockItem
                    isAutocomplete={isDE_D365}
                    isDisabled={isStockCodeReadOnly}
                    isInvalid={isStockCodeInvalid}
                    item={item}
                    isAutocompleteListInTop={false}
                    isAutoChangeDescription={!(isDE_D365 && isTier1)}
                    overwriteRgDescriptionDialogEnabled={hasOverwriteRgDescriptionDialogEnabled}
                  />
                </Col>
              </FormGroup>
            )}

            {this._renderDescription}

            <ProductFormAndQuality
              isExisted={this._hasProductFormAndQuality}
              stockItemId={this._stockItemId}
              productFormId={item.productForm?.id}
              shouldDownloadForm={Boolean(this._stockItemId)}
              onSelectForm={item.setProductForm}
              productFormClassName={this._isValidProductForm ? '' : BORDER_DANGER}
              productQualityId={item.productQuality?.id}
              shouldDownloadQuality={Boolean(this._stockItemId && item.productForm)}
              onSelectQuality={item.setProductQuality}
              productQualityClassName={this._isValidProductQuality ? '' : BORDER_DANGER}
              isReadOnly={isReadOnly && !isTier1}
            />

            <FormGroup row>
              <Label data-test="gross-weight-label" xs={3} md={3}>
                {this._hasGrossWeightLabel ? t.ADVISEDGOOD_ROUTE_GROSS_WEIGHT : t.GLOBAL_LABEL_WEIGHT}
              </Label>
              <Col xs={9} md={5}>
                <InputNumber
                  data-test="sorting-report-gross-weight"
                  disabled={isRGWeightReadOnly}
                  decimalPlaces={numberOfDecimals}
                  onChangeValue={(value: number) => item.setGrossWeight(value)}
                  step={inputNumberStep(numberOfDecimals)}
                  value={item.grossWeight}
                  className={classnames({ [BORDER_DANGER]: !this._isValidGrossWeight() })}
                  allowNegativeValue={allowNegativeGrossWeight}
                  onBlur={onBlurGrossWeightHandler}
                />
              </Col>
            </FormGroup>
            {this._renderNetWeight}
            {this._renderUnloadLocation}
            {!isDE && (
              <FormGroup row className="mb-0 mt-2" data-test="printer-section">
                <Label xs={3}>&nbsp;</Label>
                <Col xs={9} className="flex">
                  {isDE_D365 ? (
                    <LasernetPrintComponent receivedGoodId={item.id} documentType={this._printDocumentType} />
                  ) : (
                    <React.Fragment>
                      <PrintComponent
                        advisedGood={this.props.advisedGood}
                        selectedReceivedGood={item}
                        printers={printers}
                      />
                      {!env.isProduction && (
                        <Button
                          data-test="qr-button"
                          size="sm"
                          className="margin-left-1rem float-start"
                          onClick={this._openPrintYardModal}
                        >
                          QR
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                </Col>
              </FormGroup>
            )}
          </Col>
        </Row>
        {/* MACHINE USAGE - ONLY LOCATION IS USED FOR NOW */}
        {this._renderMachineUsage}

        {/* CONTAMINATIONS */}
        {this._renderContamination}

        {/* CLASSIFICATION */}
        {this._renderClassification}

        {/* PROCESSING TIME */}
        {this._renderProcessingTime}

        {/* PICTURES */}
        {this._hasPictures && (
          <PicturesComponent
            data-test="pictures"
            isReadOnly={isReadOnly}
            content={item}
            uploadAttachment={receivedGoodsStore.uploadAttachment}
            uploadAttachments={receivedGoodsStore.uploadAttachments}
            downloadAttachments={receivedGoodsStore.downloadAttachments}
          />
        )}

        <MaterialsComponent
          isReadOnly={isReadOnly}
          materials={item.materials.items}
          nonDefaultMaterial={nonDefaultMaterial}
        />

        {/* MEASUREMENT */}
        {this._renderMeasurement}

        {this._hasTransfer && (
          <TransferComponent
            item={item}
            advisedGood={advisedGood}
            transferDeliveredGood={transferDeliveredGood}
            isReadOnly={isReadOnly}
          />
        )}

        <SortingReportDetailSection
          data-test="lab-analysis-section"
          title={t.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS}
          getHeaderToolsComponent={() => this._labAnalysisCreateComponent}
        >
          {this._hasLabAnalysis ? (
            <Row data-test="lab-analysis" className="margin-0-auto bg-light-grey">
              <Col className="pt-2">
                {item.labInputs.map((labInput: LabAnalysisModel, index: number) => (
                  <AdvisedGoodLabAnalysisComponent
                    key={`la-${labInput.id ? labInput.id : index}`}
                    labInput={labInput}
                    receivedGoodId={item.id}
                    labStatuses={labStatuses}
                    deleteLabInput={item.deleteLabInputById}
                  />
                ))}
              </Col>
            </Row>
          ) : (
            <Row className="bg-white border-top border-bottom text-center margin-0-auto" data-test="no-lab-analysis">
              <Col className="pt-2 pb-2">{t.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS}</Col>
            </Row>
          )}
        </SortingReportDetailSection>

        <CostDetailsComponent item={item}/>

        <h5 className="mt-4">{t.GLOBAL_LABEL_REMARKS}</h5>
        <Row className="bg-white border-top border-bottom">
          <Col className="pt-2 pb-2">
            <InputWithNullCheck
              data-test="remarks-input"
              className="min-height-80"
              disabled={isRemarksReadOnly}
              name="remarks"
              onChange={(e: React.FormEvent<HTMLInputElement>) => item.setRemarks(e.currentTarget.value)}
              type="textarea"
              value={item.remarks}
            />
          </Col>
        </Row>

        {this._hasWiDone && (
          <FormGroup className="margin-top-1rem">
            <Label
              data-test="wi-done-label"
              check
              className={classnames(['display-inline-block', { [TEXT_DANGER]: !this._isValidWiDone() }])}
            >
              {t.ADVISEDGOOD_SORTINGREPORT_WI_DONE}
              <InputWithNullCheck
                type="checkbox"
                data-test="wi-done"
                id="WI-done"
                className="margin-left-0-5rem float-end"
                disabled={isReadOnly}
                checked={item.wiDone}
                onChange={this._changeWiDone}
              />
            </Label>
          </FormGroup>
        )}

        {isPrintYardModalOpen && (
          <Modal
            data-test="print-yard-modal"
            isOpen={isPrintYardModalOpen}
            toggle={this._closePrintYardModal}
            backdrop="static"
          >
            <ModalHeader toggle={this._closePrintYardModal} />
            <img className="image-by-width" src={receivedGoodsStore.printYard(item.id)} alt="qr code image" />
          </Modal>
        )}

        {/* MODAL CONFIRMATION FOR LAB ANALYSIS CREATION */}
        <ModalConfirmation
          data-test="lab-analysis-create-modal"
          isOpen={this.state.labAnalysisCreateConfirmationModal}
          toggleModal={this._toggleLabAnalysisCreateConfirmationModal}
          ico="flask"
          heading={t.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER}
          text={t.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT}
          callbackYes={this._labAnalysisCreate}
          buttonYesColor="success"
        />
      </Container>
    );
  }

  private get _hasStockCode() {
    const { viewStore: { isTier3FromDE_D365 }, sessionStore: { isTier3 } } = this.props;
    return !isTier3 || isTier3FromDE_D365;
  }

  private get _labAnalysisCreateComponent() {
    const { labStatusSelected } = this.state;
    const {
      item,
      translateService: { t },
      commonStore: { labInputTypes },
    } = this.props;

    return this._hasLabAnalysisCreation ? (
      <div className="float-end" data-test="sorting-report">
        {/* CREATE NEW LAB ANALYSIS */}
        <InputWithNullCheck
          bsSize="sm"
          data-test="lab-analysis-input"
          className={classnames(['float-start', 'width-140', 'me-1', 'ms-1', 'max-height-24', 'padding-top-0'])}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ labStatusSelected: e.target.value })}
          type="select"
          value={labStatusSelected}
        >
          <option value="" disabled>
            {t.GLOBAL_LABEL_SELECT}
          </option>
          {labInputTypes &&
            labInputTypes.map((labInput: IdNameModel) => (
              <option key={labInput.id} value={labInput.id} disabled={item.hasLabInputWithName(labInput.name)}>
                {getTranslation(`STATUS_LABINPUT_${labInput.name.toUpperCase()}` as keyof I18N, t)}
              </option>
            ))}
        </InputWithNullCheck>
        <Button
          disabled={!labStatusSelected}
          size="sm"
          className={classnames(['float-start', 'max-height-24', 'line-height-1'])}
          color="secondary"
          type="button"
          onClick={this._labAnalysisCreateSelector}
          data-test="lab-analysis-btn"
        >
          {t.GLOBAL_LABEL_CREATE}
        </Button>
      </div>
    ) : null;
  }

  private get _renderMachineUsage() {
    const {
      translateService: { t },
      viewStore: { isDE, isUS, isDE_D365 },
    } = this.props;

    return (
      (isDE || isUS || isDE_D365) && (
        <SortingReportDetailSection title={t.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE}>
          <div className="machine-usage-section">
            {this._renderTargetLocation}
            {/* ONLY LOCATION IS USED FOR NOW */}
            {/* { this._renderMachine } */
            /* NOSONAR */}
            {/* { this._renderTargetBatch } */
            /* NOSONAR */}
          </div>
        </SortingReportDetailSection>
      )
    );
  }

  private get _renderUnloadLocation() {
    const {
      advisedGood,
      translateService: { t },
      commonStore: { yardLocations },
    } = this.props;
    return this._hasUnloadLocation ? (
      <FormGroup row className="flex mt-1">
        <Label xs={3} md={3}>
          {t.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION}
        </Label>
        <Col xs={9} md={5}>
          <Autocomplete
            data-test="unload-location"
            disabled={true}
            placeholder={t.GLOBAL_LABEL_SELECT}
            items={yardLocations}
            selectedValue={advisedGood.yardLocation && advisedGood.yardLocation.id}
          />
        </Col>
      </FormGroup>
    ) : null;
  }

  private get _hasProductFormAndQuality() {
    const {
      item: { stockCode },
      commonStore: { requiresStockItemProductFormAndQuality },
      viewStore: { isDE_D365 },
    } = this.props;

    return isDE_D365 && requiresStockItemProductFormAndQuality(stockCode);
  }

  private get _renderTargetLocation() {
    const {
      item,
      translateService: { t },
      commonStore: { yardLocations },
    } = this.props;
    return (
      <FormGroup row className="flex mt-1 full-width">
        <Label xs={4} md={3}>
          {t.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION}
        </Label>
        <Col xs={8} md={5}>
          <Autocomplete
            data-test="target-location"
            disabled={this._isTargetLocationDisabled}
            placeholder={t.GLOBAL_LABEL_SELECT}
            items={yardLocations}
            onSelect={(selectedItem: IdNameModel) => item.setTargetLocation(selectedItem)}
            selectedValue={item.targetLocation && item.targetLocation.id}
            hasClearButton={this._canClearTargetLocation}
          />
        </Col>
      </FormGroup>
    );
  }

  private get _renderMachine() {
    const {
      item,
      translateService: { t },
      receivedGoodsStore: { machines },
    } = this.props;

    return machines && machines.length ? (
      <FormGroup row className="flex mt-1 full-width" id={SortingReportRequiredFields.machine}>
        <Label xs={4} md={3}>
          {t.ADVISEDGOOD_SORTINGREPORT_MACHINE}
        </Label>
        <Col xs={8} md={5}>
          <Autocomplete
            data-test="machine"
            disabled={!this._isMachineEnabled}
            placeholder={t.GLOBAL_LABEL_SELECT}
            items={machines}
            onSelect={(selectedItem: IdNameActiveModel) => item.setMachine(selectedItem)}
            selectedValue={item.machine && item.machine.id}
            hasClearButton={this._canClearMachineField}
          />
        </Col>
      </FormGroup>
    ) : null;
  }

  private get _renderTargetBatch() {
    const {
      item,
      translateService: { t },
      receivedGoodsStore: { targetBatches },
    } = this.props;
    return targetBatches && targetBatches.length ? (
      <FormGroup row className="flex mt-1 full-width" id={SortingReportRequiredFields.targetBatch}>
        <Label xs={4} md={3}>
          {t.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH}
        </Label>
        <Col xs={8} md={5}>
          <Autocomplete
            data-test="target-batch"
            disabled={!this._isTargetBatchEnabled}
            placeholder={t.GLOBAL_LABEL_SELECT}
            items={targetBatches}
            onSelect={(selectedItem: IdNameActiveModel) => item.setTargetBatch(selectedItem)}
            selectedValue={item.targetBatch && item.targetBatch.id}
            hasClearButton={this._canClearTargetBatchField}
          />
        </Col>
      </FormGroup>
    ) : null;
  }

  private get _renderContamination() {
    const {
      item,
      sessionStore: { isManager, isTraderOrBackOffice },
      viewStore: { isTier1FromDE, isIT, isDE, isFR, isDE_D365, isTier1FromDE_D365 },
      advisedGood: { isDepartmentHSS },
      isNeedToValidateRGs,
    } = this.props;
    const isTraderOrBackOfficeFromDEOrIT = (isIT || isFR || isDE || isDE_D365) && isTraderOrBackOffice;
    return (
      this._hasContamination && (
        <Contamination
          data-test="contamination"
          receivedGood={item}
          isReadOnly={this._isContaminationReadOnly}
          isShownOnlyFieldsWithValue={
            isTier1FromDE || isManager || isTraderOrBackOfficeFromDEOrIT || isTier1FromDE_D365
          }
          addContaminationPackagingSectionItems={item.addContaminationPackagingSectionItems}
          isDepartmentHSS={isDepartmentHSS}
          validate={isNeedToValidateRGs}
        />
      )
    );
  }

  private get _renderClassification() {
    const {
      receivedGoodsStore: { bulkDensities, foundQualities, mainTypes, materialDescriptions },
      item,
      viewStore: { countryCode },
      translateService: { t },
      isNeedToValidateRGs,
    } = this.props;
    const isSectionValid =
      !isNeedToValidateRGs ||
      item.validateOnlyExistingFields(['bulkDensity', 'foundQuality', 'mainType', 'materialDescription'], countryCode);
    return (
      this._hasClassification && (
        <SortingReportDetailSection
          title={t.SORTINGREPORT_CLASSIFICATION_TITLE}
          isOpen={true}
          hasFieldWithError={this._isClassificationFieldsEnabled && !isSectionValid}
          data-test="classification-section"
        >
          <FormGroup row className="classification-section">
            <Col md={3} xs={5} className="align-self-center">
              <Label className="flex">{t.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY}</Label>
            </Col>
            <Col md={5} xs={7}>
              <InputWithNullCheck
                bsSize="sm"
                data-test="bulk-density"
                className={classnames([{ [BORDER_DANGER]: !this._isClassificationFieldValid('bulkDensity') }])}
                disabled={!this._isClassificationFieldsEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.setBulkDensity(e.target.value)}
                required
                type="select"
                value={item.bulkDensity}
              >
                <option value="" disabled>
                  {t.GLOBAL_LABEL_SELECT}
                </option>
                {bulkDensities &&
                  bulkDensities.map((bulkDensity: string) => (
                    <option key={bulkDensity} value={bulkDensity}>
                      {this._getTranslationOfClassificationField(bulkDensity)}
                    </option>
                  ))}
              </InputWithNullCheck>
            </Col>
          </FormGroup>
          <FormGroup row className="classification-section">
            <Col md={3} xs={5} className="align-self-center">
              <Label>{t.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY}</Label>
            </Col>
            <Col md={5} xs={7}>
              <InputWithNullCheck
                bsSize="sm"
                data-test="found-quality"
                className={classnames([{ [BORDER_DANGER]: !this._isClassificationFieldValid('foundQuality') }])}
                disabled={!this._isClassificationFieldsEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.setFoundQuality(e.target.value)}
                required
                type="select"
                value={item.foundQuality}
              >
                <option value="" disabled>
                  {t.GLOBAL_LABEL_SELECT}
                </option>
                {foundQualities &&
                  foundQualities.map((foundQuality: string) => (
                    <option key={foundQuality} value={foundQuality}>
                      {this._getTranslationOfClassificationField(foundQuality)}
                    </option>
                  ))}
              </InputWithNullCheck>
            </Col>
          </FormGroup>
          <FormGroup row className="classification-section">
            <Col md={3} xs={5} className="align-self-center">
              <Label>{t.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE}</Label>
            </Col>
            <Col md={5} xs={7}>
              <InputWithNullCheck
                bsSize="sm"
                data-test="main-type"
                className={classnames([{ [BORDER_DANGER]: !this._isClassificationFieldValid('mainType') }])}
                disabled={!this._isClassificationFieldsEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.setMainType(e.target.value)}
                required
                type="select"
                value={item.mainType}
              >
                <option value="" disabled>
                  {t.GLOBAL_LABEL_SELECT}
                </option>
                {mainTypes &&
                  mainTypes.map((mainType: string) => (
                    <option key={mainType} value={mainType}>
                      {this._getTranslationOfClassificationField(mainType)}
                    </option>
                  ))}
              </InputWithNullCheck>
            </Col>
          </FormGroup>
          <FormGroup row className="classification-section">
            <Col md={3} xs={5} className="align-self-center">
              <Label className="classification-label">{t.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION}</Label>
            </Col>
            <Col md={5} xs={7}>
              <InputWithNullCheck
                bsSize="sm"
                data-test="material-description"
                className={classnames([{ [BORDER_DANGER]: !this._isClassificationFieldValid('materialDescription') }])}
                disabled={!this._isClassificationFieldsEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.setMaterialDescription(e.target.value)}
                required
                type="select"
                value={item.materialDescription}
              >
                <option value="" disabled>
                  {t.GLOBAL_LABEL_SELECT}
                </option>
                {materialDescriptions &&
                  materialDescriptions.map((materialDescription: string) => (
                    <option key={materialDescription} value={materialDescription}>
                      {this._getTranslationOfClassificationField(materialDescription)}
                    </option>
                  ))}
              </InputWithNullCheck>
            </Col>
          </FormGroup>
        </SortingReportDetailSection>
      )
    );
  }

  private get _renderProcessingTime() {
    const {
      item,
      advisedGood,
      isReadOnly,
      viewStore: { isDE_D365},
      translateService: { t }
    } = this.props;
    return (
      this._hasProcessingTime && (
        <ProcessingTimeComponent
          data-test="processing-time"
          title={isDE_D365 ? t.SORTINGREPORT_PROCESSING_TIME_WEIGHT_TITLE : t.SORTINGREPORT_PROCESSING_TIME_TITLE}
          processingTimes={item.processingData}
          advisedGood={advisedGood}
          isReadOnly={isReadOnly}
          decimalPlaces={NUMBER_OF_DECIMALS_PROCESSING_TIME}
        />
      )
    );
  }

  private get _renderMeasurement() {
    const { item, advisedGood, isReadOnly } = this.props;
    return (
      this._hasMeasurement && (
        <MeasurementComponent
          data-test="measurement"
          measurement={item.measurement}
          advisedGood={advisedGood}
          isReadOnly={isReadOnly}
        />
      )
    );
  }

  private get _renderNetWeight() {
    const {
      translateService: { t },
      viewStore: { numberOfDecimals },
      item,
    } = this.props;
    return (
      this._hasNetWeight && (
        <FormGroup row>
          <Label xs={3} md={3}>
            {t.ADVISEDGOOD_ROUTE_NET_WEIGHT}
          </Label>
          <Col xs={9} md={5}>
            <InputNumber
              data-test="net-weight"
              disabled={true}
              decimalPlaces={numberOfDecimals}
              step={inputNumberStep(numberOfDecimals)}
              value={item.netWeight}
            />
          </Col>
        </FormGroup>
      )
    );
  }

  private get _renderDescription() {
    const {
      isReadOnly,
      item,
      viewStore: { isUS, countryCode },
      translateService: { t },
      commonStore: { stockItems },
      descriptionMaxLength,
    } = this.props;
    const hasDangerBorder = !isReadOnly && !item.validateOnlyExistingFields(['description'], countryCode);
    return (
      <FormGroup row className="flex mt-1">
        <Label xs={3}>{t.GLOBAL_LABEL_DESCRIPTION}</Label>
        <Col xs={9}>
          {isUS ? (
            <InputWithLimitOfCharacters
              data-test="sorting-report-description-us"
              disabled={isReadOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.setDescription(e.target.value)}
              required
              type="text"
              maxLength={descriptionMaxLength}
              value={item.description}
              className={classnames({ [BORDER_DANGER]: hasDangerBorder })}
            />
          ) : (
            <Autocomplete
              data-test="sorting-report-description"
              disabled={isReadOnly}
              placeholder={t.GLOBAL_LABEL_SELECT}
              items={stockItems}
              onChange={(str: string) => item.setDescription(str)}
              onSelect={(selectedItem: StockItemModel) => item.setDescription(selectedItem.name)}
              selectedValue={item.description}
              maxLength={descriptionMaxLength}
              charactersRemainingPosition={Position.top}
              inputClassName={classnames({ [BORDER_DANGER]: hasDangerBorder })}
            />
          )}
        </Col>
      </FormGroup>
    );
  }

  private get _isTargetLocationDisabled() {
    const {
      advisedGood: { status },
      isReadOnly,
      viewStore: { isTier2FromDE, isTier2FromUS, isTier2FromDE_D365 },
    } = this.props;
    return (
      isReadOnly ||
      (status !== AdvisedGoodStatus.UNLOADED && (isTier2FromDE || isTier2FromDE_D365)) ||
      (status !== AdvisedGoodStatus.CLAIMED && isTier2FromUS)
    );
  }

  private get _isMachineEnabled() {
    const {
      advisedGood: { status },
      viewStore: { isTier2FromDE, isTier1FromDE, isTier1FromDE_D365, isTier2FromDE_D365 },
    } = this.props;
    return (
      ((isTier2FromDE || isTier2FromDE_D365) && status === AdvisedGoodStatus.UNLOADED) ||
      ((isTier1FromDE || isTier1FromDE_D365) && status === AdvisedGoodStatus.READY_FOR_SIGN_OFF)
    );
  }

  private _isUnloadedForTier2DE = () => {
    const {
      advisedGood: { status },
      viewStore: { isTier2FromDE, isTier2FromDE_D365 },
    } = this.props;
    return (isTier2FromDE || isTier2FromDE_D365) && status === AdvisedGoodStatus.UNLOADED;
  };

  private get _isTargetBatchEnabled() {
    return this._isUnloadedForTier2DE();
  }

  private get _isClassificationFieldsEnabled() {
    const { viewStore: { isTier3FromDE_D365 } } = this.props;
    return this._isUnloadedForTier2DE() || isTier3FromDE_D365;
  }

  private get _hasContamination() {
    const {
      sessionStore: { isTraderOrBackOffice, isLab, isTier2, isTier1, isManager, isAdmin },
      viewStore: { isDE, isIT, isFR, isTier3FromIT, isTier3FromFR, isDE_D365, isTier3FromDE_D365 },
    } = this.props;
    const { item } = this.props;
    return (
      item.contamination &&
      (isDE || isIT || isFR || isDE_D365) &&
      (isTraderOrBackOffice || isLab || isTier2 || isTier1 || isManager || isTier3FromIT || isTier3FromFR || isAdmin || isTier3FromDE_D365)
    );
  }

  private get _isContaminationReadOnly() {
    const {
      isReadOnly,
      sessionStore: { isLab, isTraderOrBackOffice, isManager, isAdmin },
      viewStore: { isTier1FromDE, isTier1FromDE_D365 },
    } = this.props;
    return (
      (isReadOnly && !isAdmin) || isLab || isTraderOrBackOffice || isTier1FromDE || isManager || isTier1FromDE_D365
    );
  }

  private get _hasLabAnalysisCreation() {
    const {
      isReadOnly,
      viewStore: {
        isIT,
        isFR,
        isLabFromDE,
        isLabFromDE_D365,
        isTier2FromIT,
        isTier1FromIT,
        isTier3FromIT,
        isTier3FromFR,
        isTier2FromFR,
        isTier1FromFR,
      },
      advisedGood,
    } = this.props;
    return (
      !(isIT || isFR || isReadOnly) ||
      isLabFromDE ||
      isLabFromDE_D365 ||
      (!isReadOnly &&
        (((isTier1FromIT || isTier3FromIT || isTier2FromIT || isTier1FromFR || isTier3FromFR || isTier2FromFR) &&
          advisedGood.status === AdvisedGoodStatus.UNLOADED) ||
          (advisedGood.status === AdvisedGoodStatus.READY_FOR_TIER_2 && (isTier2FromIT || isTier2FromFR))))
    );
  }

  private get _hasPictures() {
    const {
      viewStore: { isIT, isDE, isUS, isFR, isDE_D365 },
    } = this.props;
    return isDE || isIT || isUS || isFR || isDE_D365;
  }

  private get _hasWiDone() {
    const {
      viewStore: { isDE },
      advisedGood: { isDepartmentSTST },
    } = this.props;
    return isDE && isDepartmentSTST;
  }

  private get _hasClassification() {
    const {
      viewStore: { isDE, isDE_D365 },
      advisedGood: { isDepartmentSTST },
    } = this.props;
    return (isDE && isDepartmentSTST) || isDE_D365;
  }

  private _labAnalysisCreateSelector = () => {
    const {
      viewStore: { isIT, isFR },
    } = this.props;

    if (isIT || isFR) {
      this._toggleLabAnalysisCreateConfirmationModal();
    } else {
      this._labAnalysisCreate();
    }
  };

  private _toggleLabAnalysisCreateConfirmationModal = () => {
    this.setState({ labAnalysisCreateConfirmationModal: !this.state.labAnalysisCreateConfirmationModal });
  };

  private _labAnalysisCreate = () => {
    const { labStatusSelected } = this.state;
    const { labAnalysisCreate, item } = this.props;

    labAnalysisCreate(item, labStatusSelected);
    this.setState({ labStatusSelected: '' });
  };

  private get _hasLabAnalysis(): boolean {
    const { item } = this.props;

    return item.labInputs && item.labInputs.length !== 0;
  }

  private get _hasProcessingTime() {
    const {
      sessionStore: { isWeightBridgeOperator },
      viewStore: { isIT, isFR, isDE_D365 },
    } = this.props;
    const { item } = this.props;
    return item.processingData && (isIT || isFR || isDE_D365 && item.stockItem?.valuable) && !isWeightBridgeOperator;
  }

  private get _hasMeasurement() {
    const {
      sessionStore: { isWeightBridgeOperator },
      viewStore: { isIT, isFR },
    } = this.props;

    return (isIT || isFR) && !isWeightBridgeOperator;
  }

  private get _canClearTargetLocation(): boolean {
    const {
      isReadOnly,
      viewStore: { isTier2FromDE, isTier2FromUS, isTier2FromDE_D365 },
    } = this.props;
    return !isReadOnly && (isTier2FromDE || isTier2FromUS || isTier2FromDE_D365);
  }

  private get _canClearMachineField(): boolean {
    const {
      isReadOnly,
      viewStore: { isTier1FromDE, isTier2FromDE, isTier1FromDE_D365 },
    } = this.props;
    return !isReadOnly && (isTier2FromDE || isTier1FromDE || isTier1FromDE_D365);
  }

  private get _canClearTargetBatchField(): boolean {
    const {
      isReadOnly,
      viewStore: { isTier2FromDE, isTier2FromDE_D365 },
    } = this.props;
    return !isReadOnly && (isTier2FromDE || isTier2FromDE_D365);
  }

  private get _hasTransfer(): boolean {
    const {
      isReadOnly,
      viewStore: { isIT, isUS, isFR, isDE_D365 },
    } = this.props;
    return !isReadOnly && !isIT && !isFR && !isUS && !isDE_D365;
  }

  private _isDEorUS = () => {
    const {
      viewStore: { isUS, isDE, isDE_D365 },
    } = this.props;
    return isDE || isUS || isDE_D365;
  };

  private get _hasUnloadLocation(): boolean {
    return this._isDEorUS();
  }

  private get _hasNetWeight(): boolean {
    return this._isDEorUS();
  }

  private get _hasGrossWeightLabel(): boolean {
    return this._isDEorUS();
  }

  private _getTranslationOfClassificationField = (key: string) => {
    return getTranslation(`SORTINGREPORT_CLASSIFICATION_${key}` as keyof I18N, this.props.translateService.t);
  };

  private _openPrintYardModal = () => {
    this.setState({ isPrintYardModalOpen: true });
  };

  private _closePrintYardModal = () => {
    this.setState({ isPrintYardModalOpen: false });
  };

  private _changeWiDone = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.item.setWiDone(e.target.checked);
  };

  private _isClassificationFieldValid = (key: ReceivedGoodsValidatorKey) => {
    const {
      item,
      isNeedToValidateRGs,
      viewStore: { countryCode },
    } = this.props;
    return (
      !isNeedToValidateRGs ||
      (item.validateOnlyExistingFields([key], countryCode) && this._isClassificationFieldsEnabled)
    );
  };

  private _isValidGrossWeight = (): boolean => {
    const {
      item,
      isRGWeightReadOnly,
      isNeedToValidateRGs,
      viewStore: { countryCode },
    } = this.props;
    return !isNeedToValidateRGs || isRGWeightReadOnly || item.validateOnlyExistingFields(['grossWeight'], countryCode);
  };

  private _isValidWiDone = () => {
    const {
      item,
      isNeedToValidateRGs,
      viewStore: { countryCode },
    } = this.props;
    return !isNeedToValidateRGs || item.validateOnlyExistingFields(['wiDone'], countryCode);
  };

  private get _isValidProductForm() {
    const {
      item,
      isNeedToValidateRGs,
      viewStore: { countryCode },
    } = this.props;
    return (!isNeedToValidateRGs && !item.stockCode) || item.validateOnlyExistingFields(['productForm'], countryCode);
  }

  private get _isValidProductQuality() {
    const {
      item,
      isNeedToValidateRGs,
      viewStore: { countryCode },
    } = this.props;
    return (
      (!isNeedToValidateRGs && !item.stockCode) || item.validateOnlyExistingFields(['productQuality'], countryCode)
    );
  }

  private get _stockItemId() {
    const {
      item,
      commonStore: {
        common: { findStockItemByCode },
      },
    } = this.props;
    if (!item.stockCode) {
      return null;
    }

    return findStockItemByCode(item.stockCode)?.id;
  }

  private get _printDocumentType() {
    return this.props.commonStore.documentTypes.find(
      (t) => t.documentTypeName === LasernetDocumentTypes.YARD_QR_CODE_LABEL
    );
  }
}

export default inject(
  'sessionStore',
  'commonStore',
  'receivedGoodsStore',
  'translateService',
  'viewStore'
)(observer(SortingReportDetailComponent));
