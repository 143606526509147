import * as React from 'react';
import * as classnames from 'classnames';
import * as FontAwesome from 'react-fontawesome';
import { inject, observer } from 'mobx-react';

import ViewStore from 'stores/ViewStore';
import TranslateService from 'services/TranslateService';
import { processDecimals } from 'util/helpers';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import AdvisedGoodWeightDetailsCellComponent from 'pod/advisedGoods/AdvisedGoodWeightDetailsCellComponent';
import { TEXT_DANGER } from 'util/constants';
import AdvisedGoodCostDetailsComponent from 'pod/advisedGoods/AdvisedGoodCostDetailsComponent';

interface IProps {
  advisedGoodsStore?: AdvisedGoodsStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
}

class AdvisedGoodWeightDetailsComponent extends React.Component<IProps> {
  public render() {
    const {
      advisedGoodsStore: { content, allWeights },
      translateService: { t },
      viewStore: { isUS, numberOfDecimals },
    } = this.props;
    if (!content || (isUS && !allWeights)) {
      return null;
    }

    const isSortingWeightMoreThanReceived =
      content.deliveryReceivedWeight && content.totalAdvisedGoodWeightWithDeductions > content.deliveryReceivedWeight;
    const processedDeliveryReceivedWeight = processDecimals(content.deliveryReceivedWeight, numberOfDecimals);
    const processedTotalGrossAdvisedGoodWeight = processDecimals(
      content.totalAdvisedGoodWeightWithDeductions,
      numberOfDecimals
    );
    const processedTotalNetAdvisedGoodWeight = processDecimals(content.totalAdvisedGoodNetWeight, numberOfDecimals);

    return (
      <table className="float-right-for-desktop-left-for-mobile">
        <tbody data-test="weight-details">
          <AdvisedGoodWeightDetailsCellComponent
            title={t.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT}
            dataTest="truck-received-weight-value"
            condition={this._hasTruckReceivedAndSortingWeight}
          >
            {allWeights && allWeights.truckReceivedWeight
              ? this._parseDecimals(allWeights.truckReceivedWeight)
              : this._zeroWeight()}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL}
            dataTest="truck-sorting-total-value"
            condition={this._hasTruckReceivedAndSortingWeight}
          >
            {allWeights && allWeights.truckSortingTotal
              ? this._parseDecimals(allWeights.truckSortingTotal)
              : this._zeroWeight()}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT}
            dataTest="advised-gross-weight-value"
            condition={this._hasAdvisedGrossWeight}
          >
            {!!content.advisedGrossWeight ? this._parseDecimals(content.advisedGrossWeight) : this._zeroWeight(false)}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT}
            dataTest="advised-net-weight-value"
            condition={this._hasAdvisedNetWeight}
          >
            {!!content.advisedNetWeight ? this._parseDecimals(content.advisedNetWeight) : this._zeroWeight(false)}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT}
            dataTest="advised-calculated-net-weight-value"
            condition={this._hasAdvisedCalculatedNetWeight}
          >
            {!!content.advisedCalculatedNetWeight
              ? this._parseDecimals(content.advisedCalculatedNetWeight)
              : this._zeroWeight(false)}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.ADVISEDGOOD_ROUTE_GROSS_WEIGHT}
            dataTest="gross-weight-value"
            condition={this._hasGrossWeight}
          >
            {this._scaleWeightIcon}
            {!!content.firstWeight ? this._parseDecimals(content.firstWeight) : this._grossWeightRequired}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT}
            dataTest="received-weight-value"
            condition={this._hasReceivedWeight}
          >
            {this._scaleWeightIcon}
            {!!content.deliveryReceivedWeight ? processedDeliveryReceivedWeight : this._tareWeightRequired}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS}
            customClass={classnames({ 'text-danger': isSortingWeightMoreThanReceived })}
            dataTest="total-gross-value"
          >
            {!!isSortingWeightMoreThanReceived && <FontAwesome name="exclamation-triangle" className="me-3" />}
            {processedTotalGrossAdvisedGoodWeight}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodWeightDetailsCellComponent
            title={t.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET}
            dataTest="sorting-total-net-value"
            condition={this._hasSortingTotalNetWeight}
          >
            {processedTotalNetAdvisedGoodWeight}
          </AdvisedGoodWeightDetailsCellComponent>

          <AdvisedGoodCostDetailsComponent/>
        </tbody>
      </table>
    );
  }

  private get _hasTruckReceivedAndSortingWeight() {
    return this.props.viewStore.isUS;
  }

  private get _hasSortingTotalNetWeight() {
    return !this.props.viewStore.isIT && !this.props.viewStore.isFR;
  }

  private get _hasAdvisedNetWeight() {
    return !this.props.advisedGoodsStore.content.nonAdvisedGood && !this.props.viewStore.isUS;
  }

  private get _hasAdvisedCalculatedNetWeight() {
    return !this.props.advisedGoodsStore.content.nonAdvisedGood && this.props.viewStore.isUS;
  }

  private get _hasAdvisedGrossWeight() {
    return this.props.viewStore.isFR || this.props.viewStore.isUS;
  }

  private get _hasGrossWeight() {
    return !this.props.viewStore.isUS;
  }

  private get _hasReceivedWeight() {
    return !this.props.viewStore.isUS;
  }

  private get _grossWeightRequired() {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;
    return (
      <div
        data-test="first-weight-required"
        className={classnames('font-12', 'display-inline-block', {
          [TEXT_DANGER]: !content.isScaleWeights,
          'second-blue': content.isScaleWeights,
        })}
        dangerouslySetInnerHTML={{
          __html: content.isScaleWeights ? t.GLOBAL_LABEL_DETAILED_WEIGHING : t.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED,
        }}
      />
    );
  }

  private get _tareWeightRequired() {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;
    return (
      <div
        data-test="second-weight-required"
        className={classnames('font-12', 'display-inline-block', {
          [TEXT_DANGER]: !content.isScaleWeights,
          'second-blue': content.isScaleWeights,
        })}
        dangerouslySetInnerHTML={{
          __html: content.isScaleWeights ? t.GLOBAL_LABEL_DETAILED_WEIGHING : t.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED,
        }}
      />
    );
  }

  private _zeroWeight(hasTextDanger = true) {
    return (
      <span data-test="zero-weight" className={hasTextDanger ? 'text-danger' : ''}>
        0
      </span>
    );
  }

  private _parseDecimals = (val: number) => {
    const {
      viewStore: { numberOfDecimals },
    } = this.props;
    return val ? val.toFixed(numberOfDecimals) : null;
  };

  private get _scaleWeightIcon() {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.isScaleWeights ? (
      <FontAwesome data-test="balance-scale-weight-icon" name="balance-scale" className="second-blue margin-right-10" />
    ) : null;
  }
}

export default inject(
  'advisedGoodsStore',
  'translateService',
  'viewStore'
)(observer(AdvisedGoodWeightDetailsComponent));
