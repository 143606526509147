import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = '是';
lang.GLOBAL_LABEL_YES_SMALL = '是';
lang.GLOBAL_LABEL_NO = '否';
lang.GLOBAL_LABEL_NO_SMALL = '否';
lang.GLOBAL_LABEL_SUBMIT = '提交';
lang.GLOBAL_LABEL_RESULT = '结果';
lang.GLOBAL_LABEL_ERROR = '错误';
lang.GLOBAL_LABEL_REFRESH = '刷新';
lang.GLOBAL_LABEL_ADD_NEW = '新增';
lang.GLOBAL_LABEL_NAME = '姓名';
lang.GLOBAL_LABEL_CODE = '代码';
lang.GLOBAL_LABEL_UNIQUE_ID = '唯一识别码';
lang.GLOBAL_LABEL_LOCATION = '位置';
lang.GLOBAL_LABEL_ONLINE = '在线';
lang.GLOBAL_LABEL_IS_DEFAULT = '为默认值';
lang.GLOBAL_LABEL_MODEL = '型号';
lang.GLOBAL_LABEL_IP = 'IP';
lang.GLOBAL_LABEL_SAVE = '保存';
lang.GLOBAL_LABEL_DELETE = '删除';
lang.GLOBAL_LABEL_CANCEL = '取消';
lang.GLOBAL_LABEL_UPLOAD = '上传';
lang.GLOBAL_LABEL_FILE = '文件';
lang.GLOBAL_LABEL_PORT = '端口';
lang.GLOBAL_LABEL_FIRST_NAME = '名字';
lang.GLOBAL_LABEL_LAST_NAME = '姓氏';
lang.GLOBAL_LABEL_USERNAME = '用户名';
lang.GLOBAL_LABEL_PASSWORD = '密码';
lang.GLOBAL_LABEL_EMAIL = '电子邮箱';
lang.GLOBAL_LABEL_ROLE = '角色';
lang.GLOBAL_LABEL_STATUS = '状态';
lang.GLOBAL_LABEL_REMARKS = '备注';
lang.GLOBAL_LABEL_SELECT = '选择';
lang.GLOBAL_LABEL_GRN = 'GRN';
lang.GLOBAL_LABEL_IN_PROGRESS = '进行中';
lang.GLOBAL_LABEL_COMPLETED = '已完成';
lang.GLOBAL_LABEL_REGISTER = '注册';
lang.GLOBAL_LABEL_OPERATION = '运行';
lang.GLOBAL_LABEL_SUPPLIER = '供应商';
lang.GLOBAL_LABEL_SUPPLIER_CITY = '供应商所在城市';
lang.GLOBAL_LABEL_DRIVER = '司机';
lang.GLOBAL_LABEL_LICENCE_PLACE = '车牌';
lang.GLOBAL_LABEL_TO_CLAIM = '要认领';
lang.GLOBAL_LABEL_CREATED = '已创建';
lang.GLOBAL_LABEL_ACTIONS = '操作';
lang.GLOBAL_LABEL_CLAIM = '认领';
lang.GLOBAL_LABEL_DESCRIPTION = '描述';
lang.GLOBAL_LABEL_ADD = '添加';
lang.GLOBAL_LABEL_NOT_CLAIMED = '未认领';
lang.GLOBAL_LABEL_DELIVERIES = '交货';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = '保存附件';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = '上传附件';
lang.GLOBAL_LABEL_ATTACHMENTS = '附件';
lang.GLOBAL_LABEL_ATTACHMENT = '附件';
lang.GLOBAL_LABEL_ADVISED_GOODS = '建议商品';
lang.GLOBAL_LABEL_SYNC = '同步';
lang.GLOBAL_LABEL_CLOSE = '关闭';
lang.GLOBAL_LABEL_VERIFY = '验证';
lang.GLOBAL_LABEL_ALERT = '！！！建议商品已标记！！！';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = '!!! 接收到的好消息是！！！';
lang.GLOBAL_LABEL_CLAIM_BY = '认领人';
lang.GLOBAL_LABEL_CLASSIFIED_BY = '归类人';
lang.GLOBAL_LABEL_VERIFIED_BY = '验证人';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = '回滚人';
lang.GLOBAL_LABEL_FLAGGED_BY = '标记者';
lang.GLOBAL_LABEL_UNFLAGGED_BY = '未标记者';
lang.GLOBAL_LABEL_TRANSFERS = '传输';
lang.GLOBAL_LABEL_TRANSFERED = '已传输';
lang.GLOBAL_LABEL_WEIGHT = '重量';
lang.GLOBAL_LABEL_CREATE = '创建';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `剩余字符：${number}`;
lang.GLOBAL_LABEL_HIDE = '隐藏';
lang.GLOBAL_LABEL_UNHIDE = '取消隐藏';
lang.GLOBAL_LABEL_SHOW = '显示';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `语言：${language}`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = '文件已下载';
lang.GLOBAL_LABEL_DECIMALS = '小数';
lang.GLOBAL_LABEL_GO_BACK = '返回';
lang.GLOBAL_LABEL_VERSION = (version) => `版本 - ${version}`;
lang.GLOBAL_LABEL_CONFIRM = '确认';
lang.GLOBAL_LABEL_FIELD = '字段';
lang.GLOBAL_LABEL_FIELDS = '字段';
lang.GLOBAL_LABEL_NONE = '无';
lang.GLOBAL_LABEL_ALL_TENANTS = '所有租户';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = '未定义输入字段名称';
lang.GLOBAL_LABEL_UNCLAIM = '取消认领';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = '证书333/EU';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = '证书授权';
lang.GLOBAL_LABEL_CERT_715_13 = '证书。 715/13';
lang.GLOBAL_LABEL_TYPE = '类型';
lang.GLOBAL_LABEL_REVISION_REQUEST = '修订要求';
lang.GLOBAL_LABEL_EXPIRED = '已过期';
lang.GLOBAL_LABEL_NO_CERTIFICATE = '无证书';
lang.GLOBAL_LABEL_RESPONSIBLE = '负责人';
lang.GLOBAL_LABEL_DEPARTMENT = '部门';
lang.GLOBAL_LABEL_EXPORT = '导出';
lang.GLOBAL_LABEL_REEXPORT = '重新导出';
lang.GLOBAL_LABEL_FLAGGED = '已标记';
lang.GLOBAL_LABEL_EXPORTED = '已导出';
lang.GLOBAL_LABEL_SYNCED = '已同步';
lang.GLOBAL_LABEL_PRINT = '打印';
lang.GLOBAL_LABEL_FLAG = '标记';
lang.GLOBAL_LABEL_UN_FLAG = '取消标记';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = '无授权号';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = '授权已过期';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = '无到期日期';
lang.GLOBAL_LABEL_AUTH_NUMBER = '授权号';
lang.GLOBAL_LABEL_EXP_DATE = '到期日期';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `${name} 无效的`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `无效的字段：${name}`;
lang.GLOBAL_LABEL_USER_ROLES = '用户角色';
lang.GLOBAL_LABEL_DEFAULT_ROLE = '默认角色';
lang.GLOBAL_LABEL_DEFAULT_TENANT = '默認租戶';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = '新版本已发布。';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = '要应用更改 - 刷新页面。所有未保存更改将丢失！';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = '有房客';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `.可用於${roleName}的租戶`;
lang.GLOBAL_LABEL_IMPORT = '进口';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `.您的密碼將於${date}過期。 請盡快更改密碼，以避免帳戶被封鎖`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = '您的密码已过期。请设置一个新的';
lang.GLOBAL_LABEL_PROCESSING = '處理中';
lang.GLOBAL_LABEL_PRICE = '價錢';
lang.GLOBAL_LABEL_UNIT = '單元';
lang.GLOBAL_LABEL_BREAKDOWN = '分解';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = '密码输入 日期';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = '意大利';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = '埃斯特罗';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = '迪塔';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = '供应商详细信息';
lang.GLOBAL_LABEL_FORM = '形式';
lang.GLOBAL_LABEL_QUALITY = '质量';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = '刷新产品变体';
lang.GLOBAL_LABEL_WEIGHT_UNITS = '重量单位';
lang.GLOBAL_LABEL_EMPLOYEE_ID = '員工ID';
lang.GLOBAL_LABEL_LEGAL_ENTITY = '法人實體';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = '产品变体';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `刷新 ${name}`;
lang.GLOBAL_LABEL_TOTAL = '全部的';
lang.GLOBAL_LABEL_SHAREPOINT = '共享点';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = '部分签收';
lang.GLOBAL_LABEL_TITLE = '标题';
lang.GLOBAL_LABEL_CLIENT_ID = '客户端 ID';
lang.GLOBAL_LABEL_CLIENT_SECRET = '客户端服务';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = '连接地址';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = '默認開啟餘額扣除';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = '详细称重';
lang.GLOBAL_LABEL_UPDATE = '更新';
lang.GLOBAL_FILTERKEY_DATE_ASC = '日期 ASC';
lang.GLOBAL_FILTERKEY_DATE_DESC = '日期 DESC';
lang.GLOBAL_FILTERKEY_STATUS_ASC = '状态 ASC';
lang.GLOBAL_FILTERKEY_STATUS_DESC = '状态 DESC';
lang.GLOBAL_FILTERKEY_ALL = '全部';
lang.GLOBAL_FILTERKEY_ONLY_MY = '仅限我的';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = '仅限未认领的';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = '仅限未同步的';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = '进行中';
lang.GLOBAL_FILTERKEY_IN_LAB = '实验室内';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = '准备第 2 级';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = '准备签收';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = '已签收';
lang.GLOBAL_FILTERKEY_COMPLETE = '完成';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = '部分注销';
lang.GLOBAL_RADIOBUTTON_DRY = '干燥';
lang.GLOBAL_RADIOBUTTON_RAIN = '雨';
lang.GLOBAL_RADIOBUTTON_SNOW = '雪';
lang.GLOBAL_RADIOBUTTON_DUMP = '自卸车';
lang.GLOBAL_RADIOBUTTON_VAN = '厢式货车';
lang.GLOBAL_RADIOBUTTON_FLATBED = '平板车';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = '货箱';
lang.GLOBAL_RADIOBUTTON_RAILCAR = '轨道车';
lang.GLOBAL_RADIOBUTTON_OTHER = '其他';
lang.GLOBAL_USERROLE_SUPERADMIN = '超级管理员';
lang.GLOBAL_USERROLE_ADMIN = '管理员';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = '地磅';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = '外部地磅';
lang.GLOBAL_USERROLE_TIER3 = '第 3 级';
lang.GLOBAL_USERROLE_TIER2 = '第 2 级';
lang.GLOBAL_USERROLE_TIER1 = '第 1 级';
lang.GLOBAL_USERROLE_MANAGER = '经理';
lang.GLOBAL_USERROLE_LAB = '实验室';
lang.GLOBAL_USERROLE_TRADER = '交易员';
lang.GLOBAL_USERROLE_TRADER_MOBILE = '交易员';
lang.GLOBAL_USERROLE_BACKOFFICE = '后台';
lang.GLOBAL_USERROLE_DATAANALYZER = '数据分析器';
lang.GLOBAL_LOGIN_HEADER = '登录';
lang.GLOBAL_LOGIN_TITLE = '登录您的账户';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = '您已退出登录';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = '开始日期';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = '结束日期';
lang.GLOBAL_RECOVERPASSWORD_HEADER = '设置 <br /> 密码';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = '密码已更新';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = '设置密码';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = '当前密码';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = '新密码';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = '重新输入新密码';
lang.GLOBAL_FILE_SELECT_ERROR = '選擇文件時出錯';
lang.GLOBAL_SERVERERROR_DEFAULT = '发生错误……';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = '外部服务出错……';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'T2 无法取消建议商品的标记。';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = 'T2无法取消标记收到的好消息。';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'T2 无法回滚建议商品的状态。';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = '.Trader 无法取消建议商品的标记。';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = '管理员无法从其当前状态回滚建议的货物。';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'T1 无法认领建议商品';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'T1 无法上传建议商品';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'T1 无法验证建议商品';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = '无法验证建议商品，因为交货尚未同步';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_ADVISED_GOOD_NOT_UNLOADED = '无法完成推荐，因为尚未下载交付';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = '已标记的建议商品无法准备签收';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = '建议商品尚未准备好签收';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = '所有商品都必须进行库存编码，然后才能签收建议商品';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = '如果您已经验证，则无法签署建议良好';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = '建议商品未填写皮重';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = '为租户禁用建议的良好级别的签字';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = '如果交货已签收，则无法更新建议商品';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_COMMENT_WHEN_READY_FOR_SIGN_OFF = '如果建议好评已准备好签收，则无法更新';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = '如果 AG 已卸载，则无法更新评论';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = '无法验证建议商品，因为缺少建议商品皮重';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = '无法验证建议商品，因为缺少交货皮重';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = '必须先填写所有库存代码，然后才能验证建议商品';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = '实验室还有一些物品';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `${fullname} 已认领建议商品`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = '交货一旦签收，则无法回滚';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = '一旦建议好的被签署，就无法回滚';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = '带有实验室样品的交货不能删除';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_LAB_SAMPLES_EXIST = '实验室样品推荐的产品不能被淘汰';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_LAST_ADVISED_GOOD_IN_DELIVERY = '交货仅包括 1 件建议商品。 请删除该交付';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = '模板不支持一种建议商品含有超过 11 件已收商品。';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = '一种建议商品最多允许 10 件已收商品。';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = '一种建议商品最多允许 19 件已收商品。';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = '从相机获取图片超时';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = '请在使用前校准相机';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = '获取 OCR 结果超时';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = '管理员角色不存在';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = 'AppRegistration 角色不存在';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = '仅允许编辑 AppRegistration 类型的帐户';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = 'Client Secret 至少需要 20 个字符，一个大写字母，一个数字，一个特殊字符';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = '客户 ID 已存在';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = '应用注册标题无效';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = '应用注册说明无效';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = '客户端密码至少需要 20 个字符';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = '无法删除租户，因为有些用户属于该租户';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = '无法为租户设置顺序，因为它已经创建了一些交付';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = '无法为租户设置法人实体，因为它已经创建了一些交付';
lang.GLOBAL_SERVERERROR_CANNOT_CHANGE_TENANT_WORKFLOW = '工作流程无法更改';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = '此网站已被其他租户使用，无法使用';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = '请提供网站';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = '请提供用于上传附件的 SharePoint 文件夹';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = '选定的 SharePoint 文件夹不存在';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = '未为租户选择用于上传附件的 SharePoint 文件夹。 请联系您的超级管理员';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = '不允许 dOps 应用程序将文件上传到选定的 SharePoint 站点。 请联系您的超级管理员';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = '外部服务错误：无法更新 SharePoint Online 上的附件元数据';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = '外部服务错误：无法登录 SharePoint Online';
lang.GLOBAL_SERVERERROR_SHAREPOINT_FOLDER_NOT_FOUND = '外部服务错误：找不到用于上传附件的 SharePoint 文件夹。 请联系超级管理员';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = '同步后无法更新交货';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = '同步交货时，交货单号为必填项';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = '无法删除已认领的建议商品';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = '同步交货时，皮重号为必填项';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = '次级建议商品必须至少含有一件物品为已收商品';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = '建议商品必须至少含有一件建议商品明细中的物品';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = '交货必须至少含有一件物品为已收商品';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = '无法减去皮重，除非建议商品已认领';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = '必须先对所有物品进行库存编码，然后才能签收交货。';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = '必须先完成所有实验室物品，然后才能签收交货。';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '有些建议商品没有填写皮重。';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = '有些收货未填写毛重';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = '有些建議商品未填寫EWC代碼';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '交货应同步后才能签收';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '不能签收建议好，交货应该同步';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = '所有建议的货物必须准备好签收，然后才能签收';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '无法完成交货，除非提供所有皮重';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = '无法完成未签收的交货';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '交货无法同步，因为地磅交货尚未同步';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = '分类无法重新同步，因为尚未签收';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = '已存在具有相同代码的材料';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `交货 ${grn} 正在使用材料`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = '无法编辑未认领的建议商品';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = '无法为完成分类报告的物品添加实验室分析';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `提供的库存代码 ${stockCode} 无效`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `找不到提供的用戶角色ID ${userRole}`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = '无法为未认领分类报告的物品添加实验室分析';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = '生成二维码图像时出现异常';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = '传输时已收商品的重量不能为空';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = '待传输的最大重量是已收商品的总重量';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = '传输之前必须设置建议商品的重量';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = '传输的最大重量不能大于建议商品的重量';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = '无法传输重量为负数的已收商品';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = '无法传输全部已收商品';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = '无法传输全部建议商品';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = '如果建议商品中只有一件已收商品，则无法传输已收商品';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = '无法为用户分配超级管理员角色';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = '密码必须至少包含 10 个字符、1 个大写字母、1 个数字、1 个特殊字符';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = '您不能删除其他管理员';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = '未记录的错误';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = '发送电子邮件时出错';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = '处理请求时出错';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = '未找到文件';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = '未找到建议商品';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = '未找到建议商品附件';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = '未找到污染物';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = '未找到次级建议商品';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = '未找到已收商品';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = '已收商品描述不能超过 79 个字符';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = '未找到相机';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = '相机未启用';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = '未找到租户';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = '未找到管理员';
lang.GLOBAL_SERVERERROR_WEIGHBRIDGE_USER_NOT_FOUND = '称重用户未找到';
lang.GLOBAL_SERVERERROR_TRADER_USER_NOT_FOUND = '交易员用户未找到';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = '未找到用户';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = '未找到位置';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = '位置已存在';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = '未找到交货';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = '未找到交货附件';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = '未找到供应商';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = '找不到供应商类型';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = '未找到公路货运商';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = '未找到材料';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = '未找到实验室输入类型';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = '未找到工作流的实验室输入类型';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = '未找到实验室输入';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = '不支持实验室状态';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = '未找到工作流的实验室状态';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND = '未找到实验室状态';
lang.GLOBAL_SERVERERROR_PROVIDE_LAB_STATUS_ID = '请提供实验室状态 ID';
lang.GLOBAL_SERVERERROR_PROVIDE_INFO_IF_LAB_STATUS_PERMITTED_OR_NOT = '请指定实验室状态是否处于活动状态';
lang.GLOBAL_SERVERERROR_PROVIDE_ID_FOR_LAB_INPUT_TYPE = '请提供实验室输入类型 ID';
lang.GLOBAL_SERVERERROR_PROVIDE_NAME_FOR_LAB_INPUT_TYPE = '请提供实验室输入类型名称';
lang.GLOBAL_SERVERERROR_PROVIDE_COST_RATE_FOR_LAB_INPUT_TYPE = '请提供实验室输入类型的成本率';
lang.GLOBAL_SERVERERROR_PROVIDE_LIST_OF_ACTIVE_STATUSES_FOR_LAB_INPUT_TYPE = '请提供实验室输入类型的活动状态列表';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_WITH_THIS_NAME_ALREADY_EXISTS = '具有此名称的实验室输入类型已存在';
lang.GLOBAL_SERVERERROR_THIS_LAB_STATUS_CANT_HAVE_MORE_THAN_ONE_UNIT = '此实验室状态不能有多个单位';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_UNITS_FOR_LAB_STATUS = '提供实验室状态的单位数';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = '未找到库存物品';
lang.GLOBAL_SERVERERROR_MANUAL_STOCK_ITEM_CREATE_UPDATE_NOT_ALLOWED = '不允许手动创建或更新库存商品';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUABLE_TYPE = '请提供库存商品估价类型';
lang.GLOBAL_SERVERERROR_CHANGE_STOCK_ITEM_VALUABLE_TYPE_NOT_ALLOWED = '不允许更改库存商品估价类型';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = '库存物品已存在';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = '库存物品代码长度应为 5 位数';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = '未找到打印机';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = '未找到磅秤';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = '未找到打印模板';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = '找不到产品表格';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = '提供的产品形式与提供的库存项目无关';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = '找不到产品质量';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = '找不到产品变型';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = 'Sign Off 需要产品变型';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = '用户名已存在';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = '无法删除您自己';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = '无效用户名或密码';
lang.GLOBAL_SERVERERROR_CANNOT_LOGIN_TENANT_IS_DELETED = '无法登录租户，因为已被删除';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = '请选择其他密码。 这个已经被使用过了';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = '当前的客户端密码已使用 2 年且已过期。 请联系超级管理员';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = '请提供有效的电子邮箱';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = '请提供用户名';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = '请提供密码';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = '密码长度至少为 10 个字符';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = '无效的客户 ID';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = '无效的客户端密码';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = '请提供名字';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = '请提供姓氏';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = '请提供用户角色';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = '请提供用户租户';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = '外部员工 ID 必须至少为 6 个符号v';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = '外部员工ID字段为必填项';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = '找不到提供ID的外部员工';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `所选员工 ID 不符合为租户工作的条件：${tenantName}`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = '請為用戶僅提供一個默認角色';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = '请为用户仅提供一个默认租户';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = '用户活跃角色必须与一个现有用户角色相符';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = '您無權訪問所選租戶';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = '只有超级管理员才能创建或编辑具有“管理员”角色的用户';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = '用戶不能同時是Trader和BackOffice';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = '具有角色 DataAnalyzer 的用户不能拥有多个角色';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = '仅当用户具有交易者，BackOffice或管理员角色时，才可以分配多个租户';
lang.GLOBAL_SERVERERROR_INVALID_NAME = '请提供姓名';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = '访问被拒绝';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = '未找到会话';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = '会话已过期';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = '请在1分钟后重试';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = '请提供库存物品名称';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = '如果您已验证其中一件建议商品，则无法签收交货';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = '已存在具有所提供代码的供应商';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = '请提供供应商名称';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = '请提供供应商代码';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = '请提供已标记状态';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = '请提供描述';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = '已收商品描述不能超过 255 个字符';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = '请提供材料信息';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = '请提供材料 ID';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = '请提供材料重量';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = '请提供次级建议商品 ID';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = '请提供 p1 的值';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = '请提供 p2 的值';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = '请提供 p3 的值';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = '请提供 p4 的值';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = '请提供对比';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = '请提供显示类型';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = '请提供相机名称';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = '请提供相机地点';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = '请提供相机唯一识别码';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = '请提供供应商';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = '请提供公路货运商';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = '请提供部门';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = '备注长度最多为 30 个字符';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = '请提供车辆登记号码';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = '请提供库存物品';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = '请提供一个建议描述';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = '建议商品描述不能超过 30 个字符';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = '建议商品描述不能超过 50 个字符';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = '建议商品描述不能超过 100 个字符';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMMENT_MAX_LENGTH_100 = '评论不能超过100个字符';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNLOADED_ATTACHMENTS_MISSING_ERROR = '请添加附件';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMPLETED_ATTACHMENTS_MISSING_ERROR = '请添加附件';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = '请提供地磅票号';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = '请提供一个位置';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = '请提供材料代码';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = '请提供材料名称';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = '请提供打印机名称';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = '请提供打印机 IP';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = '请提供打印机型号';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = '请提供打印机连接地址';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = '描述最多可包含 255 个字符';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = '地址最长为 255 个字符';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = '名称最长为 255 个字符';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = '未找到文档类型';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = '请提供 Lasernet 报告名称';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = 'Lasernet 报告名称最长为 255 个字符';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = '请提供默认份数';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = '请提供打印机 ID';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = '提供名称的打印机已存在';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = '未找到打印机配置';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = '具有提供参数的打印机配置已存在';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = '请提供份数';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = '请提供要传输的重量';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = '请提供新状态';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = '请提供磅秤名称';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = '请提供磅秤 IP';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = '请提供磅秤端口';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = '请提供交货类型';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = '请提供天气状况';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = '请提供交易类型';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = '请提供加工价格';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = '找不到加工价格';
lang.GLOBAL_SERVERERROR_PROVIDE_OPERATOR_HOURS_PROCESSING_DATA = '请提供营业时间';
lang.GLOBAL_SERVERERROR_PROVIDE_MACHINE_HOURS_PROCESSING_DATA = '请提供机器小时数';
lang.GLOBAL_SERVERERROR_PROCESSING_WEIGHT_IS_GREATER_THAN_RG_WEIGHT = '加工重量不能大于收到的货物重量';
lang.GLOBAL_SERVERERROR_PROVIDE_COST_RATE = '请提供成本率';
lang.GLOBAL_SERVERERROR_COST_RATE_NOT_FOUND = '未找到成本率';
lang.GLOBAL_SERVERERROR_PROVIDE_COST_RATE_CURRENCY = '请提供成本跟踪货币';
lang.GLOBAL_SERVERERROR_PROVIDE_COST_PER_UNIT = '请提供每单位成本值';
lang.GLOBAL_SERVERERROR_PROVIDE_COST_RATE_AMOUNT_DECIMAL_PLACES = '请提供成本跟踪金额小数位';
lang.GLOBAL_SERVERERROR_PROVIDE_COST_RATE_PRICE_DECIMAL_PLACES = '请提供成本跟踪价格小数位';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = '图像分辨率太高';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = '只允许将 PDF 文件和图像作为附件';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'GRN 不能为空';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `无法删除与传输物品有关的商品：${transfers}`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = '不支持的操作';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = '此服务器上没有可用的D365租户';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = '无法创建实验室输入，因为已存在相同类型的其他实验室输入';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = '建议商品扣减重量不能为空';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = '未找到机器';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = '未找到目标批次';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `请为 ${rgIdentifier} 提供重量`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供机器`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供已完成 WI`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供体积密度`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供材料描述`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供已发现质量`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供主要类型`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供目标批次`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = '请提供租户名称';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = '请提供租户代码';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = '请提供租户位置代码';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = '租户位置代码长度最多为 10 个字符';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = '请提供租户 GRN 前缀';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = '请提供租户的当前 GRN 值';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = '请提供租户工作流';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = '请提供租户所在时区';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = '请提供租户地区代码';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = '租户地区代码长度最多为 10 个字符';
lang.GLOBAL_SERVERERROR_TENANT_COMMENT_MAX_CHARS = '本站评论不能超过100个字符';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = '请提供租户法人实体';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = '只有附件所有者或管理员可以删除附件';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = '集成端点配置丢失。请联系管理员。';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = '只有状态为“已认领”时，才能取消认领建议商品';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = '只有认领人或管理员可以取消认领建议商品';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = '只有未创建实验室输入时，才能取消认领建议商品';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = '包装已存在';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = '未找到包装';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = '各“尺寸”污染物字段之和必须为 100%';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = '未找到处理时间';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `请在 ${rgIdentifier} 中提供污染物`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = '请提供代码';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = '请提供交货发货地';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = '未找到发货地';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = '发货地长度应为 35 个字母';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = '未找到 EWC';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = 'EWC 已经存在';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = 'GRN 已经存在';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = '交货无法导出，因为尚未签收';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = '请使用过滤器以减少要导出的交货数量。 所选的交货清单太长。 ';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = '未找到测量尺寸';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = '未找到光谱分析';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `请提供：${joinedlabelslist}`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `用户代码长度应为 ${allowedLength} 个字母`;
lang.GLOBAL_SERVERERROR_USER_WITH_THIS_CODE_ALREADY_EXISTS = '具有此代码的用户已存在于租户中';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = '请提供交货创建日期';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = '请提供建议的行号';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = '请提供交易代码';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = '请提供建议的净重';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `交付${grn}已完成，无法更新`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `交付${grn}已签署，无法更新`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = '文件为空';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = '导入合同失败 ';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = '列数与模板不符';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = '列顺序与模板不符';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = '无法上传院子位置，提供的列名称错误';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = '未能上传供应商，提供了错误的列名';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = '未能上传公路运输商，提供的列名称错误';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = '无法上传库存项目，提供了错误的列名称';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = '請提供重量單位';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = '请提供法人实体';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_NOT_FOUND = '提供的法律实体未找到';
lang.GLOBAL_SERVERERROR_EXTERNAL_API_NAME_NOT_FOUND = '找不到提供的外部 API 名称';
lang.GLOBAL_COPY_TITLE = (itemname) => `.点击将 ${itemname} 复制到剪贴板`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `.${name} 已复制到剪贴板`;
lang.HEADER_QRCODE_HEADER = '扫描二维码';
lang.HEADER_QRCODE_ERROR_MSG = '打开二维码阅读器时出错';
lang.HEADER_LABEL_LOGOUT = '注销';
lang.HEADER_LABEL_CHANGE_PASSWORD = '更改密码';
lang.HEADER_LABEL_SWITCH_ROLE = '切换角色';
lang.HEADER_LABEL_SWITCH_TENANT = '切換租戶';
lang.HEADER_MODAL_LOGOUT = '您想要注销吗？所有未保存更改将丢失。';
lang.HEADER_MODAL_SWITCH_ROLE = '您想要切换角色吗？所有未保存更改将丢失。';
lang.HEADER_MODAL_SWITCH_TENANT = '您想換房客嗎？ 所有未保存的更改將丟失。';
lang.ADMIN_SECTIONENTITY_USER = '用户';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = '库存物品';
lang.ADMIN_SECTIONENTITY_SUPPLIER = '供应商';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = '公路货运商';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = '货场位置';
lang.ADMIN_SECTIONENTITY_PRINTER = '打印机';
lang.ADMIN_SECTIONENTITY_SCALE = '磅秤';
lang.ADMIN_SECTIONENTITY_MATERIAL = '材料';
lang.ADMIN_SECTIONENTITY_MACHINE = '机器';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = '目标批次';
lang.ADMIN_SECTIONENTITY_PACKAGE = '包装';
lang.ADMIN_SECTIONENTITY_EWC = 'EWC 编号';
lang.ADMIN_SECTIONENTITY_ORIGIN = '发货地';
lang.ADMIN_ACTIVITYLOG_TITLE = '活动日志';
lang.ADMIN_ACTIVITYLOG_ACTION = '操作';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = '创建于';
lang.ADMIN_ACTIVITYLOG_USER = '用户';
lang.ADMIN_ACTIVITYLOG_MESSAGE = '消息';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = '新材料已创建';
lang.ADMIN_MATERIALS_TITLE = '材料';
lang.ADMIN_MATERIALS_CREATE_NEW = '创建新材料';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = '材料已更新';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = '材料已删除';
lang.ADMIN_MATERIALDETAIL_HEADER = '材料详情';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = '删除材料确认';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `您想要删除材料 <strong>${name}</strong> 吗？`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = '新打印机已创建';
lang.ADMIN_PRINTERLIST_HEADER = '打印机';
lang.ADMIN_PRINTERLIST_ADD_BTN = '创建打印机';
lang.ADMIN_PRINTERLIST_NAME = '打印机名称';
lang.ADMIN_PRINTERLIST_CREATE_NEW = '创建新打印机';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = '打印机已更新';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = '打印机已删除';
lang.ADMIN_PRINTERDETAIL_HEADER = '打印机位置详情';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = '删除打印机确认';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `您想要删除打印机 <strong>${name}</strong> 吗？`;
lang.ADMIN_ROADHAULIERLIST_HEADER = '公路货运商';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = '上传公路货运商';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = '授权号';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = '到期日期';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `创建新 ${name}`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `上传 ${name}`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `新 ${name} 已创建`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `${name} 已更新`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `${name} 已隐藏`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `${name} 已取消隐藏`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `${name} 详情`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `隐藏 ${name} 确认`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想要隐藏 <strong>${name}</strong> 吗？`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `取消隐藏 ${name} 确认`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想要取消隐藏 <strong>${name}</strong> 吗？`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `${name} 未找到`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = '清单上传成功！';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = '上传清单过程中出错。';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = '检查一下示例 excel 文件：';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = '新磅秤已创建';
lang.ADMIN_SCALELIST_HEADER = '磅秤';
lang.ADMIN_SCALELIST_ADD_BTN = '创建磅秤';
lang.ADMIN_SCALELIST_NAME = '磅秤名称';
lang.ADMIN_SCALELIST_CREATE_NEW = '创建新磅秤';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = '磅秤已更新';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = '磅秤已删除';
lang.ADMIN_SCALEDETAIL_HEADER = '磅秤详情';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = '删除磅秤确认';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `您想要删除磅秤 <strong>${name}</strong> 吗？`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = '未定义输入字段名称';
lang.ADMIN_STOCKEITEMLIST_HEADER = '库存物品';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = '上传库存物品';
lang.ADMIN_STOCKEITEMLIST_VALUABLE = '有价值的';
lang.ADMIN_SUPPLIERLIST_HEADER = '供应商';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = '上传供应商';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = '供应商已更新';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = '供应商已隐藏';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = '供应商已取消隐藏';
lang.ADMIN_SUPPLIERDETAIL_HEADER = '供应商详情';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = '隐藏供应商确认';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想要隐藏供应商 <strong>${name}</strong> 吗？`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = '取消隐藏供应商确认';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想要取消隐藏供应商 <strong>${name}</strong> 吗？`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = '供应商未找到';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = '机器已更新';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = '机器已隐藏';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = '机器已取消隐藏';
lang.ADMIN_MACHINEDETAIL_HEADER = '机器详情';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = '隐藏机器确认';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想要隐藏机器 <strong>${name}</strong> 吗？`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = '取消隐藏机器确认';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想要取消隐藏机器 <strong>${name}</strong> 吗？`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = '机器未找到';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = '包装已更新';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = '包装已隐藏';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = '包装已取消隐藏';
lang.ADMIN_PACKAGING_DETAIL_HEADER = '包装详情';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = '隐藏包装确认';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想要隐藏包装 <strong>${name}</strong> 吗？`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = '取消隐藏包装确认';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想要取消隐藏包装 <strong>${name}</strong> 吗？`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = '包装未找到';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = '目标批次已更新';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = '目标批次已隐藏';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = '目标批次已取消隐藏';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = '目标批次详情';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = '隐藏目标批次确认';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想要隐藏目标批次 <strong>${name}</strong> 吗？`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = '取消隐藏目标批次确认';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想要取消隐藏目标批次 <strong>${name}</strong> 吗？`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = '目标批次未找到';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = '新用户已创建';
lang.ADMIN_USERLIST_HEADER = '用户管理';
lang.ADMIN_USERLIST_ADD_BTN = '创建新用户';
lang.ADMIN_USERDETAIL_UPDATE_MSG = '用户已更新';
lang.ADMIN_USERDETAIL_DELETE_MSG = '用户已删除';
lang.ADMIN_USERDETAIL_DELETE_BTN = '删除用户';
lang.ADMIN_USERDETAIL_HEADER = '用户详情';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = '删除用户确认';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `您想要删除用户 <strong>${name}</strong> 吗？`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = '新货场位置已创建';
lang.ADMIN_YARDLOCATIONLIST_HEADER = '货场位置';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = '创建货场位置';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = '上传货场位置';
lang.ADMIN_YARDLOCATIONLIST_NAME = '货场位置名称';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = '创建新货场位置';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = '货场位置已更新';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = '货场位置已删除';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = '货场位置详情';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = '删除位置';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `您想要删除位置 <strong>${name}</strong> 吗？`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = '货场位置';
lang.ADMIN_MACHINE_LIST_HEADER = '机器';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = '上传机器';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = '目标批次';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = '上传目标批次';
lang.ADMIN_PACKAGING_ADD_BTN = '添加包装';
lang.ADMIN_PACKAGING_UPLOAD_BTN = '上传包装';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = '创建新包装';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = '新包装已创建';
lang.ADMIN_EWCLIST_HEADER = 'EWC';
lang.ADMIN_EWCLIST_ADD_BTN = '创建 EWC';
lang.ADMIN_EWCLIST_UPLOAD_BTN = '上传 EWC';
lang.ADMIN_EWCLIST_CODE = '代码';
lang.ADMIN_EWCLIST_CREATE_NEW = '创建新 EWC';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = '新 EWC 代码已添加';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = 'EWC 代码已更新';
lang.ADMIN_EWCDETAIL_HEADER = 'EWC 详情';
lang.ADMIN_EWCDETAIL_NAME = '代码';
lang.ADMIN_EWCDETAIL_HIDE_MSG = 'EWC 已隐藏';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = 'EWC 已取消隐藏';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = '隐藏 EWC 确认';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想要隐藏 EWC 代码 <strong>${name}</strong> 吗？`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = '取消隐藏 EWC 确认';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想要取消隐藏 EWC 代码 <strong>${name}</strong> 吗？`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = 'EWC 未找到';
lang.ADMIN_ORIGINLIST_HEADER = '发货地';
lang.ADMIN_ORIGINLIST_ADD_BTN = '创建发货地代码';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = '上传发货地';
lang.ADMIN_ORIGINLIST_CODE = '代码';
lang.ADMIN_ORIGINLIST_CREATE_NEW = '创建新发货地';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = '新发货地代码已添加';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = '发货地代码已更新';
lang.ADMIN_ORIGINDETAIL_HEADER = '发货地详情';
lang.ADMIN_ORIGINDETAIL_NAME = '代码';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = '发货地已隐藏';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = '发货地已取消隐藏';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = '隐藏发货地确认';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想要隐藏发货地代码 <strong>${name}</strong> 吗？`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = '取消隐藏发货地确认';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想要取消隐藏发货地代码 <strong>${name}</strong> 吗？`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = '发货地未找到';
lang.ADMIN_COSTTRACKING_CRANE = '起重机';
lang.ADMIN_COSTTRACKING_FORKLIFT = '叉车';
lang.ADMIN_COSTTRACKING_OPERATOR = '操作员';
lang.ADMIN_COSTTRACKING_OXYGEN_CUTTING = '氧气切割';
lang.ADMIN_COSTTRACKING_PLASMA_CUTTING = '等离子切割';
lang.ADMIN_COSTTRACKING_CRUSHING = '破碎';
lang.ADMIN_COSTTRACKING_TONS = '吨';
lang.ADMIN_COSTTRACKING_KILOGRAM = '千克';
lang.ADMIN_COSTTRACKING_POUNDS = '磅';
lang.ADMIN_COSTTRACKING_METRIC_TONS = 't';
lang.ADMIN_COSTTRACKING_HOURS = '小时';
lang.ADMIN_LABINPUTTYPES_TITLE = '实验室输入类型';
lang.ADMIN_LABINPUTTYPES_STATUSES = '状态';
lang.ADMIN_LABINPUTTYPES_ACTIVE = '积极的';
lang.ADMIN_LABINPUTTYPES_NUMBER_OF_UNITS = '单位数量';
lang.ADMIN_LABINPUTTYPES_ADD_STATUS = '添加状态';
lang.ADMIN_CONTRACTS_TITLE = '合约';
lang.ADMIN_CONTRACTS_FILE_IMPORT = '文件导入';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = '触发SFTP同步';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = '文件导入';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = 'SFTP导入已触发';
lang.ADMIN_PROCESSING_UNIT_MINUTES = '£/小時';
lang.ADMIN_PROCESSING_UNIT_TONS = '£/噸';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = '刷新产品变式确认';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = '刷新过程最多可能需要几分钟。 您要继续吗？';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = '产品变体成功刷新';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = '刷新产品变体仍在进行中。 2分钟后更新页面';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `刷新${name}确认`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `${name}成功刷新`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = '刷新过程最多可能需要几分钟。 您要继续吗？';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `刷新${name}仍在进行中。 2分钟后更新页面`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = '员工刷新成功';
lang.ADMIN_LASERNET_REPORT_NAME = '激光报告名称';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = '默认份数';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = '文件类型';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = '文档类型详细信息';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = '文档类型 ID';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = '请提供 Lasernet 报告名称';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = '打印机配置';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = '配置详情';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = '删除确认';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = '您想删除配置吗？';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = '确认删除成功';
lang.ADMIN_LASERNET_PRINTER_ID = '打印机 ID';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = '新租户已创建';
lang.SUPERADMIN_TENANTLIST_HEADER = '租户';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = '新增';
lang.SUPERADMIN_TENANTLIST_GRN = 'GRN 前缀';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = '创建新租户';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = '租户已更新';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = '租户已删除';
lang.SUPERADMIN_TENANTDETAIL_HEADER = '租户详情';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = '删除租户确认';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `您想要删除租户 <strong>${name}</strong> 吗？`;
lang.SUPERADMIN_TENANTDETAIL_SITE = '地点';
lang.SUPERADMIN_TENANTDETAIL_COST_TRACKING_FLAG = '启用成本跟踪';
lang.SUPERADMIN_TENANTDETAIL_CURRENCY = '货币';
lang.SUPERADMIN_TENANTDETAIL_COST_PER_UNIT = '每单位成本';
lang.SUPERADMIN_TENANTDETAIL_COST_PER_UNIT_FACTOR = '单位成本因素';
lang.SUPERADMIN_TENANTDETAIL_AMOUNT_DECIMAL_PLACES = '金额小数位数';
lang.SUPERADMIN_TENANTDETAIL_PRICE_DECIMAL_PLACES = '价格小数位数';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = '检查丢失的附件';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = '在建议的良好水平';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = '在交付级别';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = '需要分类';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = '需要污染';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = '确认排序报告中的覆盖描述';
lang.SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD = '显示收到货物的皮重';
lang.SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY = '是否需要股票代码进行验证';
lang.SUPERADMIN_TENANTDETAIL_ARE_MACHINE_HOURS_REQUIRED_FOR_VERIFY = '验证是否需要机器时间';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_HEADER = '隐藏租户确认信息';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_HEADER = '取消隐藏租户确认';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `您想隐藏租户<strong>${name}</strong>吗？`;
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `您想取消隐藏租户<strong>${name}</strong>吗？`;
lang.SUPERADMIN_TENANTDETAIL_HIDE_SUCCESS_MESSAGE = '租客被成功隐藏';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_SUCCESS_MESSAGE = '租户成功被隐藏';
lang.SUPERADMIN_TENANTSECTIONS_GENERAL_SECTION = '一般的';
lang.SUPERADMIN_TENANTSECTIONS_CONFIGURATION_SECTION = '配置';
lang.SUPERADMIN_TENANTSECTIONS_AG_CONFIGURATION_SECTION = '肠道过滤器使用者 ';
lang.SUPERADMIN_TENANTSECTIONS_DELIVERY_CONFIGURATION_SECTION = '利弗伦';
lang.SUPERADMIN_TENANTSECTIONS_REQUIRED_OPTIONS_SECTION = '符合人体工程学的选项';
lang.SUPERADMIN_TENANTSECTIONS_INTEGRATION_SECTION = '一体化';
lang.SUPERADMIN_TENANTSECTIONS_COST_TRACKING_SECTION = '免费电话收费';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = '租户已更新';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = '管理员已添加';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = '管理员已删除';
lang.SUPERADMIN_TENANTADMIN_TENANT = '租户';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = '位置代码';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = '地区代码';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = '当前 GRN 值';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = '当前实验室序列值';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = '工作流';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = '选择工作流';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = '时区';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = '集成 API 基础';
lang.SUPERADMIN_TENANTADMIN_ADMINS = '管理员';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = '添加管理员';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = '无管理员';
lang.SUPERADMIN_TENANTADMIN_HEADER = '添加管理员用户';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = '管理员用户';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = '隐藏管理员';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `您想要隐藏 <strong>${name}</strong> 吗？`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = '取消隐藏管理员';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `您想要取消隐藏 <strong>${name}</strong> 吗？`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = '选择 Sharepoint 站点';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = '图片文件夹路径';
lang.SUPERADMIN_SHAREPOINT_SITES = 'Sharepoint 网站';
lang.SUPERADMIN_APP_USERS_TITLE = '应用注册';
lang.SUPERADMIN_APP_USERS_ADD_MSG = '添加了应用注册';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = '添加应用注册';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = '应用注册已更新';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = '生成随机秘密';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = '复制此值，因为保存并关闭此应用程序用户后您将无法检索密钥';
lang.SUPERADMIN_CONNECTIONS_TITLE = '连接';
lang.SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG = '客户端 ID 和密码已成功更新';
lang.SUPERADMIN_CONNECTIONS_D365_DESCRIPTION = 'D365 主数据和 YardLink API 连接';
lang.SUPERADMIN_CONNECTIONS_SHAREPOINT_DESCRIPTION = 'SharePoint Online API 连接';
lang.MENU_ADMIN_TITLE = '管理';
lang.MENU_ADMIN_USER_MANAGEMENT = '用户管理';
lang.MENU_ADMIN_ACTIVITY_LOG = '活动日志';
lang.MENU_ADMIN_STOCK_ITEMS = '库存物品';
lang.MENU_ADMIN_SUPPLIERS = '供应商';
lang.MENU_ADMIN_ROAD_HAULIERS = '公路货运商';
lang.MENU_ADMIN_YARD_LOCATION = '货场位置';
lang.MENU_ADMIN_PRINTERS = '打印机';
lang.MENU_ADMIN_LASERNET_PRINTERS = '激光网络打印机';
lang.MENU_ADMIN_DOCUMENT_TYPES = '文件类型';
lang.MENU_ADMIN_CONFIGURATIONS = '配置';
lang.MENU_ADMIN_SCALES = '磅秤';
lang.MENU_ADMIN_MATERIALS = '材料';
lang.MENU_ADMIN_APPLICATION = '应用';
lang.MENU_ADMIN_DELIVERY_LIST = '交货清单';
lang.MENU_ADMIN_MACHINES = '机器';
lang.MENU_ADMIN_TARGET_BATCHES = '目标批次';
lang.MENU_ADMIN_PACKAGING = '包装';
lang.MENU_ADMIN_ORIGINS = '发货地';
lang.MENU_ADMIN_EWCS = 'EWC';
lang.MENU_SUPERADMIN_TENANTS = '租户';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = '实验室分析 - 已更新';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = '实验室分析状态 - 已更新';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = '交货';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = '建议描述';
lang.LABORATORY_ADVISEGOODS_TENANT = '租户';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = '分类报告';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = '实验室状态';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = '实验室清单';
lang.LABORATORY_ANALISYS_HEADER = '实验室分析';
lang.LABORATORY_ANALISYS_MEASUREMENTS = '测量尺寸';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = '实验室分析状态 - 已更新';
lang.LABORATORY_ANALISYS_WET_WEIGHT = '湿重';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = '干重';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = '铸锭重量';
lang.LABORATORY_ANALISYS_YIELD = '良率';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = '光谱分析';
lang.LABORATORY_PRINTER_SUCCESS_MSG = '页面已发送至打印机';
lang.LABORATORY_PRINTER_WARN_MSG = '使用了不受支持的打印机';
lang.LABORATORY_PRINTER_ERROR_MSG = '打印时出错';
lang.LABORATORY_PRINTER_LABEL = '标签';
lang.LABORATORY_PRINTER_NO_PRINTERS = '无打印机';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = '无法与磅秤建立连接';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = '磅秤 - 发生错误';
lang.LABORATORY_SCALE_FAKE_RESULT = '表面结果';
lang.DELIVERIES_LABORATORY_FILTER_BY = '筛选条件：';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = '新交货已接收';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `交货 ${name} 已更新`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = '建议商品已认领';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = '交货已重新同步';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `由 ${name} 分类`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'GRN 搜索';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = '建议商品已认领';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = '交货已重新同步';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = '交货已签收';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = '交货已完成';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = '签收';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = '重新同步地磅';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = '重新同步分类报告';
lang.DELIVERIES_DELIVERYLIST_RESP = '负责人';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = '签收交货？';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `您想要签收 <strong>${grn}</strong> 吗？`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = '标记为完成？';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `您想要完成 <strong>${grn}</strong> 吗？`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = '导出交货？';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `您想要导出 <strong>${grn}</strong> 吗？`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = '体重差异确认';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = '排序总重量与接收重量不匹配。 您要注销吗？';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = '发生错误，重定向回交货清单';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = '交货已创建';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = '附件上传失败，请重新上传附件';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = '合同编号';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'GRN 编号';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = '创建新交货';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = '交货单号';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = '车辆登记号码';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = '集装箱号';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = '公路货运商';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = '无附件';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = '负责人';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = '创建人';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = '签收人';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = '同步人';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = '完成人';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = '库存物品';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = '建议描述';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = '建议重量';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = '進階 重量（毛重）';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = '進階 淨重';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = '货场位置';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = '毛重';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = '皮重';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = '地磅票号';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = '净重';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = '保存更改？';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = '您想要<strong>保存更改</strong>吗？';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = '标记为完成？';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = '您想要将交货标记为<strong>完成</strong>吗？';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = '没有附件，要保存吗？';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = '交货<strong>没有附件</strong>，您想要保存吗？';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = '证书已过期，要保存吗？';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = '供应商证书已过期或丢失。 您是否仍要保存交货？';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = '发货地';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = '授权已过期，要保存吗？';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = '公路货运商授权丢失或已过期。您仍要保存交货吗？';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = '交易類型';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = '應付款';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = '轉讓';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = '拒絕退貨';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = '空洞';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = '建议的商品详细信息';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = '建议描述';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = '建议重量';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = '订单行序列';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = '电话号码';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = '交货已更新';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = '附件更新成功！';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = '附件上传失败，请重新上传附件';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = '附件已删除';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = '刪除附件確認';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = '您要刪除此附件嗎？';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `交货 <strong>${deliveryGrn}<strong/> 中的建议商品已认领`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `库存代码 [${codes}] 与 dOps 中的任何库存代码都不匹配。请联系管理员`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = '删除送货？';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = '您要删除交货吗？ 将来将无法还原它。';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `交货${name}已删除`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'EWC 编号';
lang.ADVISEDGOOD_ROUTE_DRIVER = '司机';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = '建议商品已更新';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `${entity} 中的重量已更新`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = '建议商品已标记';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = '建议商品已取消标记';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = '建议商品已完成';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = '建议商品已验证';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = '实验室分析创建成功';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = '创建传输之前必须保存建议商品';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = '创建新实验室分析之前必须保存建议商品';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = '打印之前必须保存建议商品';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = '状态回滚';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = '车辆登记';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = '毛重';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = '皮重';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = '净重';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = '毛重为必填项';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = '已接收重量';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = '皮重为必填项';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = '分类报告';
lang.ADVISEDGOOD_ROUTE_BALANCED = '已平衡';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'zip 文件已下载';
lang.ADVISEDGOOD_ROUTE_PICTURES = '照片';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = '无照片';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = '正在上传照片……';
lang.ADVISEDGOOD_ROUTE_COMMENT = '评论';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = '无评论';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `${hasFlag} 确认`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `您想要 <strong class=${className}>${hasFlag}</strong> 此建议商品吗？`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = '保存更改？';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = '您想要<strong>保存更改</strong>吗？';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = '标记为完成？';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = '您想要将此建议商品标记为<strong>完成</strong>吗？';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = '标记为已验证';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = '您想要将此建议商品标记为<strong>已验证</strong>吗？';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = '.分类总重量大于地磅的已接收重量。如要验证，请确认已在台秤上检查了分类重量。';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = '回滚至已认领状态？';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = '您想要将状态回滚至<strong>已认领</strong>吗？';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = '回滚到卸载状态？';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = '是否要将状态回滚到<strong>卸载</strong>？';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_ERROR = '请添加附件';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_WARNING = '您确定要卸载不带附件的建议商品吗';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = '分类总重量（毛重）';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = '分类总重量（净重）';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = '毛重已更新';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = '已接收重量已更新';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'EWC 编号已更新';
lang.ADVISEDGOOD_ROUTE_UPDATE_COMMENT_SUCCESS_MSG = '今日事件评论';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = '交货类型';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = '确认卸货位置';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = '选择卸货的货场位置';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = '卸货位置';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = '无法验证建议商品，因为分类总重量大于已接收重量';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = '取消认领建议商品';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = '您想要取消认领建议商品吗？';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = '建议商品已取消认领';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = '已收商品数量不能大于 10';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = '货车已接收重量（毛重）';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = '货车分类总重量（毛重）';
lang.ADVISEDGOOD_ROUTE_PACKAGING = '打包';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = '没有包装';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = '注销 AG';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = '注销AG？';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = '您想注销 AG 吗？';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'AG成功签约';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = '不带附件保存？';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = '您想保存<strong>不带附件</strong>的建议吗？';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = '您想覆盖项目的描述吗？';
lang.ADVISEDGOOD_ROUTE_DELETE = '删除建议好不好？';
lang.ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION = '您想删除“建议的好”吗？将来将无法恢复它。';
lang.ADVISEDGOOD_ROUTE_DELETED = (name) => `建議好已刪除${name} `;
lang.ADVISEDGOOD_ROUTE_VALUABLE_WEIGHT = '有价值的重量';
lang.ADVISEDGOOD_ROUTE_COST = '成本';
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = '分类报告详情';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = '无法传输已收商品，因为传输重量大于当前重量';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = '已收商品已传输';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = '库存代码';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = '传输';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = '实验室分析';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = '未请求实验室分析';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = '材料';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'WI 已完成';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = '创建新实验室分析';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = '您想要创建新实验室分析吗？';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = '删除实验室分析';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `您想要删除 <strong>${labAnalysisName}</strong> 请求吗？`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = '机器';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = '目标批次';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = '体积密度';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = '已发现质量';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = '主要类型';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = '材料描述';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `您需要填写 <strong>${emptyRequiredField}</strong> 后才能保存`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = '添加照片之前必须保存建议商品';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = '更改位置';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = '目标位置';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = '实验室备注';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = '删除照片确认';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = '您想要删除此照片吗？';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = '游离水';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = '游离油';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = '游离乳剂';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = '游离雪/冰';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = '无扣减';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = '扣减';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = '确认建议商品已卸货';
lang.ADVISEDGOOD_UNLOAD_TITLE = '已卸货';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = '建议商品卸货已完成';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = '负体重确认';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = '材料的重量为负。 请确认';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = '收到的成績很差';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = '收到的貨真不錯';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `您是否要<strong class=${className}> ${hasFlag} </ strong>收到好評？`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = '污染物';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = '其他';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = '无污染物';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = '非金属附着物';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = '水（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = '游离油（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = '尘土（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = '木材（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = '塑料（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = '玻璃棉（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = '炉渣（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = '沥青（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = '附着物油/脂（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = '硬纸板（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = '金属附着物';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = '铁（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = '锰钢（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = '铬铁（含铬 10%）（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = '铬铁（含铬 13%）（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_301 = '301 不锈钢（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = '铬铁（含铬 17%）（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = '粉碎机（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = '焚烧炉垫（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = '各种金属';
lang.RECEIVED_GOOD_CONTAMINATION_CU = '铜（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_AL = '铝（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_MS = '低碳钢（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_PB = '铅（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_TI = '钛（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = '不锈钢/铜冷却器（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = '粉碎垫（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = '固体屑末';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = '铬铁屑末（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = '低合金屑末（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = '不锈钢屑末（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = '304 不锈钢屑末（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = '316 不锈钢屑末（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = '非熔炉内尺寸';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = '用于移动剪（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = '用于固定剪（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = '用于火焰切割（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = '用于压机（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = '无需集料（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = '包括空心体（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = '材料形式';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = '袋装（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = '压块（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = '铸锭（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = '细粒材料（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = '钢水料（千克）';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = '皮重（输入）';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = '总计（件）';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = '桶（件）';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = '箱（件）';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = '方格箱（件）';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = '大袋（件）';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = '托盘（件）';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = '皮重（输出）';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = '屑末组成';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = '磁性物含量';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = '金属屑末';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = '磨屑';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = '尺寸';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = '尺寸 0 -15 厘米';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = '尺寸 16 -35 厘米';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = '尺寸 36 -60 厘米';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = '尺寸 61 -80 厘米';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = '尺寸 81 -120 厘米';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = '尺寸 121 -150 厘米';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = '尺寸 150 -999 厘米';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = '总和非 100%';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = '包装';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = '分类';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = '很轻';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = '轻';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = '中';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = '重';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = '很重';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = '铁 - SDS';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = '混合铬 - SDS';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'V13F-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = '1.4510-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = '1.4512-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = '17er Cr-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = '1.4016-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = '1.4509-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = '304-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = '铬镍 - SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = '铬镍钼 - SDS';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = '铁屑末';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = '混合铬/铬镍屑末';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = '铬镍屑末';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = '铬镍钼屑末';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = '其他';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = '粉碎机';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = '固体';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = '屑末';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = '包装';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = '压块';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = '铸锭';
lang.SORTINGREPORT_CLASSIFICATION_FINES = '细粒';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = '废物焚烧';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = '钢水料';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = '拆解废料';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = '生产废料';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = '回收废料';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = '剪切废料';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = '含固体的屑末';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = '处理时间';
lang.SORTINGREPORT_PROCESSING_TIME_WEIGHT_TITLE = '加工重量';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = '切割时间（弯曲/压碎）';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = '抓斗时间';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = '推车时间';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = '操作员时间';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = '分钟。';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = '总氮';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TIME = '小时';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_KILOGRAM = '千克';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = '操作员';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = '叉车';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = '起重机';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = '割炬';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = '等离子体';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = '起重机剪';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = '断头台剪';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = '致密剂';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = '打包机';
lang.SORTINGREPORT_PROCESSING_TIME_OXYGEN_CUTTING = '氧气切割';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA_CUTTING = '等离子切割';
lang.SORTINGREPORT_PROCESSING_TIME_CRUSHING = '破碎';
lang.SORTINGREPORT_PROCESSING_TITLE = '处理中';
lang.SORTINGREPORT_PROCESSING_SORTATION_TIME = '分拣时间';
lang.SORTINGREPORT_MEASUREMENT_TITLE = '测量尺寸';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = '样品湿重（克）';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = '样品干重（克）';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = '湿度 (%)';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = '用于熔化（克）';
lang.SORTINGREPORT_MEASUREMENT_INGOT = '铸锭（克）';
lang.SORTINGREPORT_MEASUREMENT_YIELD = '良率 (%)';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = '容器';
lang.SORTINGREPORT_LASERNET_COPIES = '副本';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `${numberOfCopies} 个标签已排队，将在 1 分钟内打印`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = '打印机设置错误。 请联系您的管理员';
lang.SORTINGREPORT_COSTDETAILS_TITLE = '费用详情';
lang.SORTINGREPORT_COSTDETAILS_TOTAL_COST = '总成本';
lang.SORTINGREPORT_COSTDETAILS_SORTATION_COST = '分拣成本';
lang.SORTINGREPORT_COSTDETAILS_LAB_COST = '实验室成本';
lang.SORTINGREPORT_COSTDETAILS_PROCESSING_COST = '加工成本';
lang.STATUS_LABINPUT_PRE_SAMPLE = '取样前';
lang.STATUS_LABINPUT_SAMPLE = '取样';
lang.STATUS_LABINPUT_RE_SAMPLE = '重新取样';
lang.STATUS_LABINPUT_SORTATION = '分类';
lang.STATUS_LABINPUT_NITON_ANALYSIS = 'Niton 分析';
lang.STATUS_LABSTATUSES_IN_LAB = '实验室内';
lang.STATUS_LABSTATUSES_DRYING = '正在干燥';
lang.STATUS_LABSTATUSES_MELTING = '正在熔化';
lang.STATUS_LABSTATUSES_MELTED = '已熔化';
lang.STATUS_LABSTATUSES_COMPLETE = '完成';
lang.STATUS_LABSTATUSES_REQUESTED = '已请求';
lang.STATUS_LABSTATUSES_SAMPLED = '已取样';
lang.STATUS_LABSTATUSES_IN_PROGRESS = '进行中';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = '进行中';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = '准备第 2 级';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = '准备签收';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = '部分注销';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = '已签收';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = '完成';
lang.STATUS_ADVISEDGOOD_WAITING = '等待中';
lang.STATUS_ADVISEDGOOD_CLAIMED = '分类中';
lang.STATUS_ADVISEDGOOD_UNLOADED = '已卸货';
lang.STATUS_ADVISEDGOOD_IN_LAB = '实验室内';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = '准备第 2 级';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = '准备签收';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = '已签收';
lang.STATUS_ADVISEDGOOD_COMPLETE = '完成';
lang.DASHBOARD_TITLES_DASHBOARD = '控制面板';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = '交付中';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = '交货（按状态）';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = '平均分拣时间';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = '平均周转时间';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = '最后交货分拣时间';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = '已标记物品';
lang.DASHBOARD_TITLES_TREES_SAVED = '树已保存';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = '分拣时间（小时）';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = '周转时间（小时）';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = '分拣时间';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `进行中 ${condition} 个月`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `进行中 ${condition} 月`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `正在进行 ${condition} 周`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `标记项目 ${condition} 个月`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `标记项目 ${condition} 月`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `标记项目 ${condition} 周`;
lang.USA_CONFIGURATION_CONFIGURATION = '配置';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = '显示合同编号';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = '显示建议商品明细';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = '显示建议商品皮重';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = '显示建议商品位置';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = '显示交货毛重/皮重/净重';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = '显示交货类型';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = '上传辐射文件';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = '显示天气状况';
lang.USA_CONFIGURATION_STOCK_CODE = '库存代码格式';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = '地磅公差';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = '辐射文件上传失败，请重新上传辐射文件';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = '辐射文件为必填项';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = '天气状况';
lang.USA_CONFIGURATION_DELIVERY_TYPE = '交货类型';
lang.USA_CONFIGURATION_GROSS = '毛重';
lang.USA_CONFIGURATION_TARE = '皮重';
lang.USA_CONFIGURATION_NET = '净重';
lang.USA_CONFIGURATION_RADIATION_FILE = '辐射文件';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = '无辐射文件';
lang.USA_CONFIGURATION_ADV_TARE = '建议皮量';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = '創建新交貨';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = '交貨準備簽收';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = '交付已簽收';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = '貿易商完成交割';

export default lang;
