import * as React from 'react';
import * as H from 'history';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Button } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import TenantModel, { ITenantModelValidationKeys } from 'models/TenantModel';
import { createSections } from 'pod/superAdmin/tenants/createSections';
import TranslateService from 'services/TranslateService';
import { TenantSectionsModel } from 'models/TenantSectionsModel';
import SectionNavigation from 'pod/superAdmin/tenants/SectionNavigation';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import WorkflowModel from 'models/WorkflowModel';
import { instance as notification } from 'util/notification';
import { joinWithCommaSeparator } from 'util/helpers';

interface ITenantDetailProps {
  tenant: TenantModel;
  history: H.History;
  translateService?: TranslateService;
  superAdminStore?: SuperAdminStore;
}

class TenantDetail extends React.Component<ITenantDetailProps> {
  private tenantSections: TenantSectionsModel = new TenantSectionsModel({
    sections: createSections(this.props.tenant, this._getTenantCountryCode, this.props.translateService)
  });

  public constructor(props) {
    super(props);
    reaction(
      () => this.props.tenant.workflowId,
      () => {
        this.tenantSections.setSections(createSections(this.props.tenant, this._getTenantCountryCode, this.props.translateService));
      }
      );
    reaction(
      () => this.props.tenant.costTrackingEnabled,
      () => {
        this.tenantSections.setSections(createSections(this.props.tenant, this._getTenantCountryCode, this.props.translateService));
      }
      );
  }

  render() {
    const { tenant, translateService: { t } } = this.props;
    const { firstActiveSection: { component: SectionComponent, name, index },
      isLastSectionActive,
      isFirstSectionActive,
      setActiveSection
    } = this.tenantSections;
    const isNewTenant = !tenant.id;

    return <div className="section" data-test="tenant-detail-container">
      <SectionNavigation tenant={tenant} sections={ this.tenantSections.sections } setActiveSection={ this.tenantSections.setActiveSection}/>
      <div className="section-content">
        <SectionComponent/>
      </div>
      <div className="nav-btn">
        <Button
          data-test="cancel-btn"
          onClick={this._goToTenantList}
        >
          {t.GLOBAL_LABEL_CANCEL}
        </Button>
        {
          isNewTenant && <Button
            data-test="create-button"
            className="margin-left-1rem"
            color="success"
            onClick={ this._createTenant }
          >
            <FontAwesome name="plus" className="me-2"/>
            { t.GLOBAL_LABEL_CREATE }
          </Button>
        }
        {
          !isNewTenant && <Button
            data-test="save-button"
            className="margin-left-1rem"
            color="success"
            onClick={ this._saveTenant }
          >
            <FontAwesome name="plus" className="me-2"/>
            { t.GLOBAL_LABEL_SAVE }
          </Button>
        }
        {
          !isFirstSectionActive && <Button
            data-test="prev-button"
            type="button"
            color="success"
            onClick={() => setActiveSection(index - 1)}
            className="width-50 me-3 prev"
          >
            <FontAwesome name="arrow-left" />
          </Button>
        }

        {
          !isLastSectionActive && <Button
            data-test="next-button"
            type="button"
            color="success"
            onClick={() => setActiveSection(index + 1)}
            className="width-50 me-3 next"
          >
            <FontAwesome name="arrow-right" />
          </Button>
        }
      </div>

    </div>;
  }

  private get _getTenantCountryCode() {
    const { tenant, superAdminStore: { workflowList } } = this.props;
    return tenant.workflowId && workflowList.find((workflow: WorkflowModel) => workflow.id === tenant.workflowId)?.code;
  }

  private _createTenant = () => {
    const {
      superAdminStore,
      tenant,
      translateService: { t },
    } = this.props;

    if (this._validate()) {
      return superAdminStore.postTenant(tenant.constructSaveObj).then(() => {
        notification.success(t.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG);
        this.props.history.push(`/super-admin/tenants`);
      });
    } else {
      this.tenantSections.goToTheFirstFailedSection(tenant);
    }
  }

  private _saveTenant = () => {
    const {
      superAdminStore,
      tenant,
      translateService: { t },
    } = this.props;

    if (this._validate()) {
      return superAdminStore.putTenant(tenant.constructSaveObj, tenant.id).then(() => {
        notification.success(t.SUPERADMIN_TENANTADMIN_UPDATE_MSG);
        this.props.history.push('/super-admin/tenants');
      });
    } else {
      this.tenantSections.goToTheFirstFailedSection(tenant);
    }
  }

  private _validate = () => {
    const { tenant, translateService: { t } } = this.props;
    let result = true;
    const countryCode = this._getTenantCountryCode;
    tenant.setNeedToCheck(false);

    if (!tenant.isValidByKey('workflow')) {
      notification.error(
        t.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS('workflow')
      );
      tenant.setNeedToCheck(true);
      return false;
    }

    if (!tenant.isValid(countryCode)) {
      const invalidKeyLabels: string[] = tenant.invalidValidatorKeys
        .get(countryCode)
        .map((key) => this._getValidationMessageMap.get(key));

      notification.error(
        t.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS(joinWithCommaSeparator(invalidKeyLabels).toLowerCase())
      );
      tenant.setNeedToCheck(true);
      result = false;
    }

    return result;
  }

  private get _getValidationMessageMap(): Map<Partial<keyof ITenantModelValidationKeys>, string> {
    const {
      translateService: { t },
    } = this.props;
    return new Map<Partial<keyof ITenantModelValidationKeys>, string>([
      ['name', t.SUPERADMIN_TENANTADMIN_TENANT],
      ['code', t.GLOBAL_LABEL_CODE],
      ['locationCode', t.SUPERADMIN_TENANTADMIN_LOCATION_CODE],
      ['regionCode', t.SUPERADMIN_TENANTADMIN_REGION_CODE],
      ['grnPrefix', t.SUPERADMIN_TENANTLIST_GRN],
      ['currentSequenceValue', t.SUPERADMIN_TENANTADMIN_GRN_VALUE],
      ['currentLabSequenceValue', t.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE],
      ['timezone', t.SUPERADMIN_TENANTADMIN_TIMEZONE],
      ['workflow', t.SUPERADMIN_TENANTADMIN_WORKFLOW],
      ['site', t.SUPERADMIN_TENANTDETAIL_SITE],
      ['sharepointUploadFolderId', t.GLOBAL_LABEL_SHAREPOINT],
      ['currency', t.SUPERADMIN_TENANTDETAIL_CURRENCY],
      ['costPerUnit', t.SUPERADMIN_TENANTDETAIL_COST_PER_UNIT],
      ['amountDecimalPlaces', t.SUPERADMIN_TENANTDETAIL_AMOUNT_DECIMAL_PLACES],
      ['priceDecimalPlaces', t.SUPERADMIN_TENANTDETAIL_PRICE_DECIMAL_PLACES],
    ]);
  }

  private _goToTenantList = () => {
    this.props.history.push('/super-admin/tenants');
  }
}

export default inject('translateService', 'superAdminStore')(observer(TenantDetail));
