import { action, computed, observable, observe, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';
import UpdateModel from 'models/UpdateModel';
import { InputType } from 'reactstrap/types/lib/Input';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';
import { CountryCode } from 'util/enums';

export interface IKeyValueModelConstructObject {
  id?: string;
  name: string;
  value: number;
}

export default class KeyValueModel extends UpdateModel<KeyValueModel> implements ISetHasChanged {
  constructor() {
    super();

    makeObservable(this, {
      name: observable,
      value: observable,
      units: observable,
      inputType: observable,
      type: observable,
      constructObject: computed,
      changeName: action,
      changeValue: action,
      update: action,
    });

    observe(this, this.onChange);
  }

  public id?: string = '';

  public name: string = '';
  public value?: number = null;
  // needs for UK for processing types
  public units?: string = null;
  public inputType?: Map<CountryCode, InputType> = null;
  public type?: string = '';

  public get constructObject(): IKeyValueModelConstructObject {
    const res = {
      name: this.name,
      value: this.value,
    } as IKeyValueModelConstructObject;

    if (this.id) {
      res.id = this.id;
    }

    return res;
  }

  public onChange = (change: IObjectDidChange) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  public changeName = (newName: string) => {
    this.name = newName;
  };

  public changeValue = (newValue: number) => {
    this.value = newValue;
  };

  public update = (obj: KeyValueModel) => {
    this.updater.update(this, obj, KeyValueModel);

    return this;
  };
}
