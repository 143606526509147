import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, InputGroup } from 'reactstrap';
import InputNumber from 'components/InputNumber';
import InputWithNullCheck from 'components/InputWithNullCheck';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import RequiredLabel from 'components/RequiredLabel';
import * as classnames from 'classnames';
import { BORDER_DANGER } from 'util/constants';

interface ICostRatesSectionProps {
  tenant: TenantModel;
  translateService?: TranslateService;
  superAdminStore?: SuperAdminStore;
}

class CostRatesSection extends React.Component<ICostRatesSectionProps>{

  public componentDidMount() {
    const { superAdminStore } = this.props;
    superAdminStore.getTenantCommon();
  }

  public render() {
    const {
      tenant,
      translateService: { t },
      superAdminStore: { currencies, tenantUnits },
    } = this.props;

    if (!tenant) {
      return null;
    }

    return <>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="currency" sm={4} required={tenant.costTrackingEnabled}>
          {t.SUPERADMIN_TENANTDETAIL_CURRENCY}
        </RequiredLabel>
        <Col sm={8}>
          <InputGroup size="md" className="full-width">
            <InputWithNullCheck
              data-test="currency"
              type="select"
              id="currency"
              value={tenant.currency}
              onChange={this._changeCurrency}
              className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('currency') })}
              required
            >
              <option value="" disabled />
              {currencies &&
                currencies.map((currency: string) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
            </InputWithNullCheck>
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="costPerUnit" sm={4} required={tenant.costTrackingEnabled}>
          {t.SUPERADMIN_TENANTDETAIL_COST_PER_UNIT}
        </RequiredLabel>
        <Col sm={8}>
          <InputGroup size="md" className="full-width">
            <InputWithNullCheck
              data-test="costPerUnit"
              type="select"
              id="costPerUnit"
              value={tenant.costPerUnit}
              onChange={this._changeUnit}
              className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('costPerUnit') })}
              required
            >
              <option value="" disabled />
              {tenantUnits &&
                tenantUnits.map((unit: string) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
            </InputWithNullCheck>
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="cost-per-unit-factor" sm={4}>
          {t.SUPERADMIN_TENANTDETAIL_COST_PER_UNIT_FACTOR}
        </RequiredLabel>
        <Col sm={8}>
          <InputNumber
            data-test="cost-per-unit-factor"
            id="cost-per-unit-factor"
            decimalPlaces={10}
            max={1000}
            onChangeValue={this._changeCostPerUnitFactor}
            value={tenant.costPerUnitFactor}
            required
            disabled
          />
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="amount-decimal-places" sm={4} required={tenant.costTrackingEnabled}>
          {t.SUPERADMIN_TENANTDETAIL_AMOUNT_DECIMAL_PLACES}
        </RequiredLabel>
        <Col sm={8}>
          <InputNumber
            data-test="amount-decimal-places"
            id="amount-decimal-places"
            decimalPlaces={0}
            max={5}
            min={0}
            onChangeValue={this._changeAmountDecimalPlaces}
            value={tenant.amountDecimalPlaces}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('amountDecimalPlaces') })}
            required
          />
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="price-decimal-places" sm={4} required={tenant.costTrackingEnabled}>
          {t.SUPERADMIN_TENANTDETAIL_PRICE_DECIMAL_PLACES}
        </RequiredLabel>
        <Col sm={8}>
          <InputNumber
            data-test="price-decimal-places"
            id="price-decimal-places"
            decimalPlaces={0}
            max={5}
            min={0}
            onChangeValue={this._changePriceDecimalPlaces}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('priceDecimalPlaces') })}
            value={tenant.priceDecimalPlaces}
            required
          />
        </Col>
      </FormGroup>
    </>
  }

  private _changeCostPerUnitFactor = (value: number) => {
    const { tenant } = this.props;
    tenant.setCostPerUnitFactor(value);
  };

  private _changeAmountDecimalPlaces = (value: number) => {
    const { tenant } = this.props;
    tenant.setAmountDecimalPlaces(value);
  };

  private _changePriceDecimalPlaces = (value: number) => {
    const { tenant } = this.props;
    tenant.setPriceDecimalPlaces(value);
  };

  private _changeCurrency = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setCurrency(e.target.value);
  };

  private _changeUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setCostPerUnit(e.target.value);
    tenant.setCostPerUnitFactor(0);
  };
}

export default inject('translateService', 'superAdminStore')(observer(CostRatesSection));
