import * as React from 'react';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import ViewStore from 'stores/ViewStore';
import SessionStore from 'stores/SessionStore';
import AdvisedGoodWeightDetailsCellComponent from 'pod/advisedGoods/AdvisedGoodWeightDetailsCellComponent';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import { getTranslation } from '../../util/helpers';
import { I18N } from '../../../assets/i18n/i18n';

interface AdvisedGoodCostDetailsComponentProps {
  advisedGoodsStore?: AdvisedGoodsStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  sessionStore?: SessionStore;
}

class AdvisedGoodCostDetailsComponent extends React.Component<AdvisedGoodCostDetailsComponentProps>{

  public render(): JSX.Element {
    const {
      viewStore: { isDE_D365 },
      translateService: { t },
      advisedGoodsStore: { content: { totalCost, valuableWeight, costPerUnit} }
    } = this.props;

    return this._shouldShowCost && <>
      <AdvisedGoodWeightDetailsCellComponent
        title={this._costTitle}
        dataTest="total-cost"
        condition={Boolean(totalCost)}
      >
        {this._parseDecimals(totalCost)}
      </AdvisedGoodWeightDetailsCellComponent>

      <AdvisedGoodWeightDetailsCellComponent
        title={t.ADVISEDGOOD_ROUTE_VALUABLE_WEIGHT}
        dataTest="valuable-weight"
        condition={Boolean(valuableWeight)}
      >
        {this._parseDecimals(valuableWeight)}
      </AdvisedGoodWeightDetailsCellComponent>

      <AdvisedGoodWeightDetailsCellComponent
        title={this._costPerUnitTitle}
        dataTest="cost-per-unit"
        condition={Boolean(costPerUnit)}
      >
        {this._parseDecimals(costPerUnit)}
      </AdvisedGoodWeightDetailsCellComponent>
    </>;
  }

  private get _shouldShowCost(): Boolean {
    const {
      viewStore: { isDE_D365 },
      sessionStore: { isTier1, isTier2, isManager, isTrader},
      advisedGoodsStore: { content: { totalCost, valuableWeight, costPerUnit} }
    } = this.props;
    const isRoleAccessible = isTier1 || isTier2 || isManager || isTrader;
    const hasCostData = totalCost && valuableWeight && costPerUnit;
    return Boolean(isDE_D365 && isRoleAccessible && hasCostData);
  }

  private get _costTitle(): string {
    const {
      sessionStore: { tenant: { currency } },
      translateService: { t }
    } = this.props;

    return `${t.ADVISEDGOOD_ROUTE_COST} (${currency})`;
  }

  private get _costPerUnitTitle(): string {
    const {
      sessionStore: { tenant: { currency, costPerUnit } },
      translateService: { t }
    } = this.props;
    const units = getTranslation(`ADMIN_COSTTRACKING_${costPerUnit.replace(' ', '_').toUpperCase()}` as keyof I18N, t);
    return `${t.SUPERADMIN_TENANTDETAIL_COST_PER_UNIT} (${currency}/${units})`;
  }

  private _parseDecimals = (val: number) => {
    const {
      sessionStore: { tenant: {priceDecimalPlaces} },
    } = this.props;
    return val ? val.toFixed(priceDecimalPlaces) : null;
  };
}

export default inject('translateService', 'sessionStore', 'viewStore', 'advisedGoodsStore')
(observer(AdvisedGoodCostDetailsComponent));
