import KeyValueModel from 'models/KeyValueModel';
import TenantModel from 'models/TenantModel';

export interface IRGProcessingType {
  fieldName: string;
  units: string;
}

export const processingTypeMapper = (types: IRGProcessingType[] = [], tenant: TenantModel): KeyValueModel[] => {
  return types.map((type: IRGProcessingType) => new KeyValueModel().update({
    name: type.fieldName,
    units: type.units === 'WEIGHT' ? tenant.units : type.units,
  } as KeyValueModel));
}
