import RootService from 'services/RootService';
import UploadAndDownloadAttachmentsAbstractStore from 'stores/UploadAndDownloadAttachmentsAbstractStore';
import { action, computed, observable, makeObservable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import { ITransferData } from 'models/TransferModel';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import RootStore from 'stores/RootStore';
import KeyValueModel from 'models/KeyValueModel';
import { IRGProcessingType, processingTypeMapper } from 'pod/receivedGoods/processingTypesMapper';

export interface ICommonDataForSortingReportResponse {
  machines: IdNameModel[];
  targetBatches: IdNameModel[];
  packaging: IdNameModel[];
  bulkDensities: { values: string[]; defaultBulkDensity: string };
  foundQualities: string[];
  mainTypes: string[];
  materialDescriptions: string[];
  processingTypes: IRGProcessingType[];
}

export interface IReceivedGoodsCommonData extends Omit<ICommonDataForSortingReportResponse, 'processingTypes' | 'bulkDensities'> {
  processingTypes: KeyValueModel[];
  bulkDensities: string[];
}

export default class ReceivedGoodsStore extends UploadAndDownloadAttachmentsAbstractStore {
  public constructor(protected readonly rootService: RootService, private readonly rootStore: RootStore) {
    super(rootService);

    makeObservable<ReceivedGoodsStore, '_setCommonSortingReportData'>(this, {
      machines: observable,
      targetBatches: observable,
      packaging: observable,
      bulkDensities: observable,
      defaultBulkDensity: observable,
      foundQualities: observable,
      mainTypes: observable,
      materialDescriptions: observable,
      processingTypes: observable,
      isSortingReportDataExist: computed,
      commonSortingReportData: computed,
      _setCommonSortingReportData: action,
    });
  }

  public machines: IdNameModel[] = [];

  public targetBatches: IdNameModel[] = [];

  public packaging: IdNameModel[] = [];

  public bulkDensities: string[] = [];

  public defaultBulkDensity = '';

  public foundQualities: string[] = [];

  public mainTypes: string[] = [];

  public materialDescriptions: string[] = [];

  public processingTypes: KeyValueModel[] = [];

  public get isSortingReportDataExist(): boolean {
    return !!(
      this.machines &&
      this.machines.length &&
      this.targetBatches &&
      this.targetBatches.length &&
      this.packaging &&
      this.packaging.length &&
      this.processingTypes
    );
  }

  public get commonSortingReportData(): IReceivedGoodsCommonData {
    return {
      machines: this.machines ?? [],
      targetBatches: this.targetBatches ?? [],
      packaging: this.packaging ?? [],
      bulkDensities: this.bulkDensities ?? [],
      foundQualities: this.foundQualities ?? [],
      mainTypes: this.mainTypes ?? [],
      materialDescriptions: this.materialDescriptions ?? [],
      processingTypes: this.processingTypes ?? [],
    }
  }

  public urlSection: string = 'received-goods';

  public printYard = (id: string) => {
    return this.rootService.ajaxService.download(`received-goods/${id}/print-yard.png`);
  };

  public postFlagReceivedGood = (receivedGoodId: string, isFlagged: boolean) => {
    return this.rootService.ajaxService.post(`received-goods/${receivedGoodId}/flag`, { isFlagged });
  };

  public getCommonDataForSortingReportRequest = () => {
    return this.rootService.ajaxService.get('common/received-good');
  };

  public getCommonDataForSortingReport = async (): Promise<IReceivedGoodsCommonData> => {
    if (this.isSortingReportDataExist) {
      return this.commonSortingReportData;
    }
    const res: ICommonDataForSortingReportResponse = await this.getCommonDataForSortingReportRequest();
    this._setCommonSortingReportData(res);

    return this.commonSortingReportData;
  };

  public getReceivedGood(receivedGoodId: string): Promise<ReceivedGoodsModel> {
    const { defaultMaterials, nonDefaultMaterial } = this.rootStore.commonStore;
    const { countryCode, rgContaminationsSectionRequired, rgClassificationsSectionRequired } = this.rootStore.viewStore;
    return this.rootService.ajaxService.get(`received-goods/${receivedGoodId}`).then((x) =>
      new ReceivedGoodsModel({
        countryCode,
        rgClassificationsSectionRequired,
        rgContaminationsSectionRequired,
      }).update(x, defaultMaterials, nonDefaultMaterial)
    );
  }

  /* BOF LAB ANALYSIS */
  public postLabAnalysis(receivedGoodId: string, labStatusSelected: string) {
    return this.rootService.ajaxService.post(`lab-analysis/${receivedGoodId}`, {
      labInputTypeId: labStatusSelected,
    });
  }

  /* EOF LAB ANALYSIS */

  // TRANSFER RECEIVED GOOD
  public postTransferDeliveredGood(
    deliveredGood: ReceivedGoodsModel,
    transfer: ITransferData
  ): Promise<AdvisedGoodsModel> {
    return this.rootService.ajaxService
      .post(`received-goods/${deliveredGood.id}/transfer`, transfer)
      .then((res) => this.rootStore.advisedGoodsStore.getUpdatedAGModelInstanceWithProcessing(res));
  }

  private _setCommonSortingReportData = (data: ICommonDataForSortingReportResponse) => {
    this.machines = data.machines;
    this.targetBatches = data.targetBatches;
    this.packaging = data.packaging;
    this.bulkDensities = data.bulkDensities.values;
    this.defaultBulkDensity = data.bulkDensities.defaultBulkDensity;
    this.mainTypes = data.mainTypes;
    this.foundQualities = data.foundQualities;
    this.materialDescriptions = data.materialDescriptions;
    this.processingTypes = processingTypeMapper(data.processingTypes, this.rootStore.sessionStore.tenant);
  };
}
