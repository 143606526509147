import { action, computed, makeObservable, observable } from 'mobx';
import { IActive, ICode, IConstructSaveObj, IName } from 'models/ModelInterfaces';
import ValidateModel from 'models/ValidateModel';
import { CountryCode } from 'util/enums';

interface IStockItemModelValidationKeys {
  code: boolean;
}

interface IValidationRules {
  code: string;
}

export type TStockItemModelValidatorKey = keyof IStockItemModelValidationKeys;

export default class StockItemModel extends ValidateModel<IStockItemModelValidationKeys>
  implements ICode, IName, IActive, IConstructSaveObj<StockItemModel> {
  public constructor(private readonly validationRules?: IValidationRules) {
    super();
    makeObservable(this, {
      code: observable,
      name: observable,
      active: observable,
      valuable: observable,
      constructSaveObj: computed,
      update: action,
      changeName: action,
      changeCode: action,
      changeActiveStatus: action,
      changeValuable: action,
    });
  }
  public id: string = '';
  public requiresFormAndQuality?: boolean = false;
  public code: string = '';
  public name: string = '';
  public active: boolean = false;
  public valuable: boolean = false;

  public get constructSaveObj(): StockItemModel {
    return {
      name: this.name,
      code: this.code,
      active: this.active,
    } as StockItemModel;
  }

  public generalValidatorKeys: Array<keyof IStockItemModelValidationKeys> = ['code'];

  public validationKeysByCountryCode: Map<CountryCode, Array<keyof Partial<IStockItemModelValidationKeys>>> = new Map<
    CountryCode,
    Array<keyof Partial<IStockItemModelValidationKeys>>
  >([
    [CountryCode.US, this.generalValidatorKeys],
    [CountryCode.IT, this.generalValidatorKeys],
    [CountryCode.DE, this.generalValidatorKeys],
    [CountryCode.DE_D365, this.generalValidatorKeys],
    [CountryCode.UK, this.generalValidatorKeys],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  public get validators() {
    return {
      code: this.validationRules ? new RegExp(this.validationRules.code).test(this.code) : true,
    };
  }

  public update = (obj: StockItemModel) => {
    this.updater.update(this, obj, StockItemModel);
    return this;
  };

  public changeName = (newName: string) => (this.name = newName);

  public changeCode = (newCode: string) => {
    if (this.needToCheck) {
      this.setNeedToCheck(false);
    }
    this.code = newCode;
  };

  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);
  public changeValuable = (newValuable: boolean) => (this.valuable = newValuable);
}
