import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Label } from 'reactstrap';
import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import SortingReportDetailSection from 'pod/receivedGoods/SortingReportDetailSection';
import { processDecimals } from 'util/helpers';
import ViewStore from 'stores/ViewStore';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';

interface ICostDetailsProps {
  item: ReceivedGoodsModel;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

class CostDetailsComponent extends React.Component<ICostDetailsProps> {

  public render() {
    const {
      translateService: { t },
      item: { totalCost, labCost, processingCost, sortingCost},
    } = this.props;

    return totalCost && (
      <SortingReportDetailSection
        title={t.SORTINGREPORT_COSTDETAILS_TITLE}
        data-test="cost-details"
      >
        {this._createCostRow(t.SORTINGREPORT_COSTDETAILS_TOTAL_COST, totalCost, 'total-cost-details')}
        {this._createCostRow(t.SORTINGREPORT_COSTDETAILS_SORTATION_COST, sortingCost, 'sorting-cost-details')}
        {this._createCostRow(t.SORTINGREPORT_COSTDETAILS_LAB_COST, labCost, 'lab-cost-details')}
        {this._createCostRow(t.SORTINGREPORT_COSTDETAILS_PROCESSING_COST, processingCost, 'processed-cost-details')}
      </SortingReportDetailSection>
    );
  }

  private _createCostRow(title: string, cost: number, dataTest: string): JSX.Element {
    return <div className="flex mt-1 full-width row mb-3" data-test={dataTest}>
      <Label className="col-4 col-md-3">{title}</Label>
      {this._renderCost(cost, dataTest)}
    </div>
  }

  private _renderCost(cost: number, dataTest: string): JSX.Element {
    const { sessionStore: { tenant: { currency, priceDecimalPlaces } } } = this.props;
    return cost ?
      <div className="col-8 col-md-5 font-medium" data-test={`${dataTest}-value`}>
        { `${processDecimals(cost, priceDecimalPlaces)} ${currency}` }
      </div>
      : null;
  }
}

export default inject('sessionStore', 'translateService', 'viewStore')(observer(CostDetailsComponent));
